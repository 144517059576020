const REGEX_EXPONENCIAL = /^[\d.]+e[+-]?\d+$/i;

export function formatedDate(d) {
  let dateFormated = "";
  try {
    if (d) {
      const date = new Date(d);
      dateFormated = new Intl.DateTimeFormat("en-US", {
        timeZone: "UTC",
      }).format(date);
    } else {
      return "";
    }
    return dateFormated;
  } catch (error) {
    return "";
  }
}

export function formatDateYMD(date) {
  var dateYMD = new Date(date.getTime() - (date.getTimezoneOffset() * 60000 ))
    .toISOString()
    .split("T")[0];
  return dateYMD
}


export function objetoVazio(obj) {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) return false;
  }
  return true;
}

export function numericOnly(event) {
  // restringir caracteres 'e' e 'E' em input do tipo number
  const charCode = event.keyCode;
  if (charCode === 69) {
    event.preventDefault();
    return false;
  }
  return true;
}



export function formatNumberFloat(number) {
  // Conversão de valores float para decimais
  if (!!number) {
    number = parseFloat(number);
    const decimais = REGEX_EXPONENCIAL.test(number)
      ? parseInt(number.toString().split("-")[1]) +
        parseInt(number.toString().split(".")[1].split("e")[0].length)
      : parseInt(number.toString().split(".")[1]?.length || 0);
    return parseFloat(number).toFixed(decimais);
  }
  return "";
}

export function numericField(){
  const elementsList = document.querySelectorAll("input[type='number']")
  elementsList && elementsList.forEach((el, index) => {
    el.addEventListener('keydown', (evt) => {
      var numero = (evt.keyCode >=48 && evt.keyCode <= 57) || (evt.keyCode >=96 && evt.keyCode <= 105)
      var controles = [8, 9, 46, 37, 38, 39, 40, 108, 110, 188, 190, 194].includes(evt.keyCode)
      var isCharSpecial = ('!@#$%¨&*()_°ºª§=').includes(evt.key)
      var isPontoFlutuante = el.value.includes(',')
      isPontoFlutuante = el.value.includes('.')
      if (!numero && !controles) return evt.preventDefault();
      if (isPontoFlutuante && (evt.keyCode === 188 || evt.keyCode === 110)) return evt.preventDefault();
      if (isPontoFlutuante && (evt.keyCode === 190 || evt.keyCode === 194)) return evt.preventDefault();
      if (isCharSpecial) return evt.preventDefault();
    })
  })
}
