import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { FieldArray, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import validationSchema from "./plotEventEditValidationSchema";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import PopoverCustom from "../../layout/PopoverCustom";
import {
  fetchEnumerationEventOperationType,
  fetchEnumerationEventTypeCrop,
  fetchEnumerationNameOperation,
  fetchEnumerationEventApplicationType,
  fetchEnumerationEventApplicationUnit,
  fetchEnumeracionEventOperationMachinery,
  fetchEnumerationEventOperationMachineryImplements,
  fetchEnumerationEventOperationEcoinvet,
  fetchEnumerationEventMainOperationProducts,
  fetchEnumerationEventGenericHasTransport,
  fetchEnumerationEventGenericTransportType,
  fetchEnumerationEventGenericTransportMachinery,
  fetchEnumerationEventGenericTransportImplements,
  resetEnumerationEventOperationMachineryImplements,
  checkEventGenericHasMachinery,
  checkEventTransportHasMachinery,
  fetchEnumerationPesticideFoliarInterception,
  fetchEnumerationPesticideApplicationMethods,
  //resetEnumerationEventGenericTransportImplements,
} from "../../../redux/enumeratedSlice";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import ProductsForm from "../../products/ProductsForm";
import {
  addPlotEvent,
  checkEventDateIsNotConflicted,
  editPlotEvent,
  fetchEditEventData,
  fetchEventForEdition,
  fetchUserAll,
} from "../../../redux/userAllSlice";
import {
  formatDateYMD,
  numericField,
  numericOnly,
  objetoVazio,
} from "../../../hooks/functions";
import { v4 as uuidv4 } from "uuid";
import CloseIcon from "@mui/icons-material/CancelRounded";
import MachineryForm from "../../machinery/MachineryForm";
import ImplementForm from '../../implements/ImplementForm';

export const PlotEventForm = (props) => {

  const formikRef = useRef();

  const {
    userAll,
    cropName,
    openPlotEventFormEdit,
    setOpenPlotEventFormEdit,
    fetchEnumerationEventOperationType,
    plot_terrain,
    fetchEnumerationEventTypeCrop,
    fetchEnumerationEventApplicationUnit,
    fetchEnumerationEventOperationMachineryImplements,
    eventOperationMachineryImplements,
    plot,
    editPlotEvent,
    fetchEnumerationEventGenericHasTransport,
    eventGenericHasTransport,
    fetchEnumerationEventGenericTransportMachinery,
    eventGenericTransportMachinery,
    fetchUserAll,
    plotEventSelected,
    fetchEditEventData,
    fetchEnumerationEventApplicationType,
    fetchEnumerationEventMainOperationProducts,
    eventMainOperationProducts,
    checkEventGenericHasMachinery,
    checkEventTransportHasMachinery,
    fetchEnumerationPesticideFoliarInterception,
    fetchEnumerationPesticideApplicationMethods,
    checkEventDateIsNotConflicted,
    fetchEnumerationEventGenericTransportImplements,
    fetchEnumeracionEventOperationMachinery,
    eventOperationMachinery,
    eventGenericTransportType,
    fetchEnumerationEventGenericTransportType
  } = props;

  const [eventType, setEventType] = useState("");
  const [openProductForm, setOpenProductForm] = useState(false);
  const [productSelected, setProductSelected] = useState("");
  const [ecoinventAmountUnit, setEcoinventAmountUnit] = useState("");
  const [loadingForm, setLoadingForm] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [renderForm, setRenderForm] = useState(false);
  const [typeApplication, setTypeApplication] = useState([]);
  const [hasMachinery, setHasMachinery] = useState(false);
  const [machineryTransportSelected, setMachineryTransportSelected] = useState([]);
  const [typeApplicationAdd, setTypeApplicationAdd] = useState("");
  const [startDate, setStarDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [openMachineryForm, setOpenMachineryForm] = useState(false);
  const [loading, setLoading] = useState(true)
  const [openImplementForm, setOpenImplementForm] = useState(false);
  const [origin, setOrigin] = useState('');
  const [machinerySelected, setMachinerySelected] = useState({})
  const [operationNameSelected, setOperationNameSelected] = useState("")
  const [transportNameSelected, setTransportNameSelected] = useState("")
  const [machineryTransportList, setMachineryTransportList] = useState([])
  const [indexAddMachinery, setIndexAddMachinery] = useState(0)
  const [update, setUpdate] = useState(0)
  const [operationMachineryTypeSelected, setOperationMachineryTypeSelected] = useState("");
  const [implementSelected, setImplementSelected] = useState({})
  const [implementTransportSelected, setImplementTransportSelected] = useState({})
  const [implementsTransportList, setImplementsTransportList] = useState([])
  const [indexAddImplement, setIndexAddImplement] = useState(0)
  const [indexAddProduct, setIndexAddProduct] = useState(0)
  const [indexAddApplication, setIndexAddApplication] = useState(0)

  const user = JSON.parse(localStorage.getItem("user"));
  const property = JSON.parse(localStorage.getItem("property"));

  async function init(){
    setTimeout(() => {
      numericField()
    }, 1000);
  }

  useEffect(()=> {
    init()
  }, [loading, loadingForm])

  useEffect(()=>{
    const start_date = new Date(plot.start_date)
    setStarDate(formatDateYMD(start_date))
    const period = plot.crop.planting_period
    let end_date = new Date()
    end_date.setDate(start_date.getDate() + period)
    setEndDate(formatDateYMD(end_date))
  },[plot])

  useEffect(() => {

    async function getEditEventData() {
      setLoadingForm(true);

      const params = {
        user_id: user.user_icv_id,
        prop_id: property._id,
        event_id: plotEventSelected._id,
        plot_id: plot._id,
      };
      const result = await fetchEditEventData(params);

      if (result.payload.main_operation.ecoinvent) {
        const getUnit = result.payload.main_operation.ecoinvent.split(",");
        setEcoinventAmountUnit(getUnit[getUnit.length - 1]);
      }

      setEventType(result.payload.type)
      setOperationNameSelected(result.payload.main_operation.name || result.payload.main_operation.ecoinvent)

      if(result.payload?.transports) {
        setTransportNameSelected(result.payload?.transports[indexAddMachinery].name)
      }

      if(result.payload.main_operation.name){
        const resultHasMachinery = await checkEventGenericHasMachinery(result.payload.main_operation.name);
        setHasMachinery(resultHasMachinery.payload);
      }

      if(result.payload.main_operation.name && hasMachinery){
        const paramsMachinery = {
          user_id: user.user_icv_id,
          prop_id: property._id,
          event_type: result.payload.type,
          op_name: result.payload.main_operation.name,
        };
        await fetchEnumeracionEventOperationMachinery(paramsMachinery);
      }

      setMachineryTransportSelected(result.payload.transports);


      

      if(typeof result.payload.main_operation.machinery !== 'undefined' && result.payload.main_operation.machinery.length > 0){
        const paramsImplements = {
          user_id: user.user_icv_id,
          prop_id: property._id,
          mach_spectype: result.payload.main_operation.machinery > 0 
            ?result.payload.main_operation.machinery[0]?.specific_type 
            : "",
          op_name: result.payload.main_operation.name,
        };

        if(paramsImplements.mach_spectype) await fetchEnumerationEventOperationMachineryImplements(paramsImplements);
      }

      if(result.payload?.transports) {
        setTransportNameSelected(result.payload?.transports[indexAddMachinery].name)
      }

      setEventType(result.payload.type)
      setOperationNameSelected(result.payload.main_operation.name || result.payload.main_operation.ecoinvent)

      if(result.payload?.transports) {
        setTransportNameSelected(result.payload?.transports[indexAddMachinery].name)
      }

      // if(result.payload.main_operation.name && hasMachinery){
      //   const paramsMachinery = {
      //     user_id: user.user_icv_id,
      //     prop_id: property._id,
      //     event_type: result.payload.type,
      //     op_name: result.payload.main_operation.name,
      //   };
      //   await fetchEnumeracionEventOperationMachinery(paramsMachinery);
      // }

      setMachineryTransportSelected(result.payload.transports);

      if(result.payload.main_operation.name){
        const resultHasMachinery = await checkEventGenericHasMachinery(result.payload.main_operation.name);
        setHasMachinery(resultHasMachinery.payload);
      }

      if(result.payload.transports){
        const listAtual = result.payload.transports.map((el, index) => {
          return result.payload.transports[index].machinery[0]?.implementList || []
        })
        setImplementsTransportList(listAtual)
      }

      const nameOperation =
        result.payload.main_operation.name ||
        result.payload.main_operation.ecoinvent;
      const params1 = {
        op_name: nameOperation,
      };
      
      const resultTypeList = await fetchEnumerationEventApplicationType(params1);
      setTypeApplication(resultTypeList.payload || []);

      if(result.payload.main_operation.name){
        await fetchEnumerationEventGenericTransportType(result.payload.main_operation.name);
      }

      let transports = []
      result.payload.transports &&
        result.payload.transports.map((t, ind) => {
          ///// verificar se é exigido maquinário
          //let transports = []
          const hasMachineryTransports = getHasTransportHasMachinery(result.payload.main_operation.name,t.name)
          .then((res) => {result.payload.transports[ind].hasMachineryTransports = res;})
          
          const isMachinery = Boolean(t.machinery[0]?._id)
          
          if (result.payload.transports[ind].possibleMachineryList){
            result.payload.transports[ind].machineryList = result.payload.transports[ind].possibleMachineryList;
          } else {
            result.payload.transports[ind].machineryList = result.payload.transports[ind].machinery[0].machineryList;
          }

          transports.push({
            _id: t._id,
            type: t.type,
            name: t.name,
            nameList: t.nameList || [],
            hasMachineryTransports,
            isMachinery: isMachinery,
            machinery: t.machinery,
            machineryList: t.machineryList || [],
          })
          return true
      });

      if(typeof result.payload.main_operation.machinery !== 'undefined' && result.payload.main_operation.machinery.length > 0){
        const paramsImplements = {
          user_id: user.user_icv_id,
          prop_id: property._id,
          mach_spectype: result.payload.main_operation.machinery.length > 0 
            ? result.payload.main_operation.machinery[0]?.specific_type
            : '',
          op_name: result.payload.main_operation.name,
        };
        await fetchEnumerationEventOperationMachineryImplements(paramsImplements);
      }
      
      if(result.payload.transports){
        const list = result.payload.transports.map((el, index) => {
          return el.machineryList
        })
        setMachineryTransportList(list)
      }

      // Montar a lista inicial de Agricultural Inputs do evento selecionado para edição
      if(result.payload.applications){
        const paramsAgriculturalInput = {
          user_id: user.user_icv_id,
          type_: result.payload.applications.length > 0
            ? result.payload.applications[indexAddProduct].type
            : '',
          prop_id: property._id,
        };
        await fetchEnumerationEventMainOperationProducts(paramsAgriculturalInput);
        setUpdate(update + 1)

        //result.payload.applications.unitList = ['a', 'b']
      }

      if(result.payload.applications){

        const newApp = result.payload.applications.map((ap, index) => {
          return {
            ...ap,
            unitList: ap.productList.find(el=> el._id === ap.product._id).unitList
          }
        })
        result.payload.applications = newApp
      }

      setInitialValues({
        ...result.payload,
        transports
      })

      await fetchEnumerationEventGenericHasTransport(
        result.payload.main_operation.name ||
          result.payload.main_operation.ecoinvent
      );

      if (result.payload.applications) {
        setProductSelected(result.payload.applications.map((ap) => ap.product));
      }
      setRenderForm(true);
      setLoadingForm(false);
    }

    if (!objetoVazio(plotEventSelected)) {
      getEditEventData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plotEventSelected]);

  useEffect(()=>{
    const getMachineryList = async () => {
      if( eventType !== "" && operationNameSelected !== "") {
        const params = {
          user_id: user.user_icv_id,
          prop_id: property._id,
          event_type: eventType,
          op_name: operationNameSelected,
        };
        await fetchEnumeracionEventOperationMachinery(params);
      }
    }
    getMachineryList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machinerySelected])




  useEffect(() => {
    if(implementSelected.data){
      const getImplements = async () => {
        const params = {
          user_id: user.user_icv_id,
          prop_id: property._id,
          mach_spectype: operationMachineryTypeSelected,
          op_name: operationNameSelected,
        };
        await fetchEnumerationEventOperationMachineryImplements(params);
      }
      getImplements()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [implementSelected]);






  useEffect(()=>{
    if (operationNameSelected !== "" && transportNameSelected !== "") {
      const getMachineryTransportList = async () => {

        const params1 = {
          user_id: user.user_icv_id,
          prop_id: property._id,
          op_name: operationNameSelected,
          t_name: transportNameSelected,
        };

        const resultMachineryTransports = await fetchEnumerationEventGenericTransportMachinery(params1);

        let atualList = machineryTransportList
        let newItem = resultMachineryTransports.payload
        atualList.splice(indexAddMachinery, 1, newItem)
        await setMachineryTransportList(atualList)

        if (formikRef.current) {
          formikRef.current.setFieldValue(
            `transports[${indexAddMachinery}].machineryList`,
            resultMachineryTransports.payload
          );
        }

        setUpdate(update + 1)
      }

      
      getMachineryTransportList()
      setUpdate(update + 1)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machineryTransportSelected])

  useEffect(() => {
    // Atualizar a lista de implementos no item em que o botão "Add implement" foi acionado
    // ao pressionar "Add implement" o sistema ira gravar o índice do item em 
    // indexAddImplement.
    // o item selecionado será atualizados pelo formImplements e ficará armazenado em 
    // implementTransportSelected
    // transportsImplementsSelected[index] deverá ser atualizado com o novo implemento
    // incluído, se este satisfizer as condições dos parâmetros exigidos. 
    if(operationNameSelected !== "" && transportNameSelected !== ""){
      const getImplementsTransport = async () => {
        const indexProp = userAll.registro.properties.findIndex((reg) => reg._id === property._id);
        const mach = userAll.registro.properties[indexProp].prop_machinery.filter((el) => el._id === machineryTransportSelected);
        const params = {
          user_id: user.user_icv_id,
          prop_id: property._id,
          op_name: operationNameSelected,
          t_name: transportNameSelected,
          mach_spectype: mach[0]?.specific_type || null,
        };
        const result = await fetchEnumerationEventGenericTransportImplements(params);
        let atualList = implementsTransportList
        let newItem =  result.payload 
        atualList.splice(indexAddImplement, 1, newItem)
        await setImplementsTransportList(atualList)
        setUpdate(update + 1)
      }
      getImplementsTransport()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [implementTransportSelected]);

  useEffect(()=>{
    if(typeApplicationAdd !== ""){
      const getProdutcs = async () => {
        
        const params = {
          user_id: user.user_icv_id,
          type_: typeApplicationAdd,
          prop_id: property._id,
        };
        const result = await fetchEnumerationEventMainOperationProducts(params);

        if (formikRef.current) {
          formikRef.current.setFieldValue(
            `applications[${indexAddApplication}].productList`,
            result.payload
          );

          setUpdate(update + 1)
        }
        
      }
      getProdutcs()
      setUpdate(update + 1)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productSelected])

  useEffect(() => {
    // Atualizar a lista de unidades de medida de produtos em applications
    if(productSelected.length && productSelected[0].name){
      const getUnit = async () => {
        await  fetchEnumerationEventApplicationUnit(productSelected[0]._id)
      }
      getUnit()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productSelected]);

  const getHasTransportHasMachinery = async (op_name, t_name) => {
    const params = {
      op_name,
      t_name,
    };
    const result = await checkEventTransportHasMachinery(params);
    return result.payload;
  };





  const handleOpenProductForm = (setFieldValue, values, index) => {
    setFieldValue(`applications[${index}].product`, "");
    setTypeApplicationAdd(values.applications[index].type);
    setOrigin('addEvent')
    setIndexAddProduct(index)
    setOpenProductForm(true);
  };

  useEffect(() => {
    const params = {
      crop_id: plot_terrain?._id,
      p_type: "plot",
    };
    plot_terrain?._id && fetchEnumerationEventTypeCrop(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventType]);

  useEffect(() => {
    eventType && fetchEnumerationEventOperationType(eventType);
  }, [eventType, fetchEnumerationEventOperationType, plotEventSelected]);

  useEffect(() => {
    if(productSelected._id){
      fetchEnumerationEventApplicationUnit(productSelected._id);
    }
  }, [fetchEnumerationEventApplicationUnit, productSelected]);
  
  const handleClose = () => {
    setEventType("");
    setOpenPlotEventFormEdit(false);
  };

  const handleChangeImplements = (event, setFieldValue, value) => {
    setFieldValue("main_operation.machinery[0].implements", value);
  };

  const handleChangeApplicationsProduct = async (event, index, setFieldValue, value, values, option) => {
    const prod = eventMainOperationProducts.registros.filter((p)=> p._id === value.props.value)
    if (prod.length > 0) {
      productSelected[index] = prod[0];
      setFieldValue(`applications[${index}].product`, prod[0]);
      setFieldValue(`applications[${index}].amount_unit`, "");
      const result = await fetchEnumerationEventApplicationUnit(event.target.value);
      await values.applications[index].productList.map((p, ind) => {
        if (prod.length > 0 && p._id === prod[0]?._id) {
          setFieldValue(`applications[${index}].unitList`, result.payload);
        }
        return true;
      });
    }
  };

  const handleChangeOperationMachinery = async (event, setFieldValue, values) => {
    setLoadingForm(true)
    const machinery_id = event.target.value;
    setFieldValue("main_operation.machinery[0]._id", machinery_id);
    setFieldValue("main_operation.machinery[0].implements", [])
    await resetEnumerationEventOperationMachineryImplements();
    const indexProp = userAll.registro.properties.findIndex((reg) => reg._id === property._id);
    const machinery = userAll.registro.properties[indexProp].prop_machinery.filter((el) => el._id === machinery_id);
    if (machinery[0]?.type === 'detailed, generic' || machinery[0]?.type === 'detailed, tractor'){
      setOperationMachineryTypeSelected(machinery[0]?.specific_type);
      const params = {
        user_id: user.user_icv_id,
        prop_id: property._id,
        mach_spectype: machinery[0]?.specific_type,
        op_name: operationNameSelected,
      };
      const result = await fetchEnumerationEventOperationMachineryImplements(params);
      setFieldValue('main_operation.machinery[0].implementList', result.payload)
    }
    setLoadingForm(false)
  };

  
  const handleChangeTransportsName = async (event, index, setFieldValue, values, value) => {
    setTransportNameSelected(value)
    setFieldValue(`transports[${index}].name`, value);
    setFieldValue(`transports[${index}].machinery`, []);
    setFieldValue(`transports[${index}].isMachinery`, false);
    setFieldValue(`transports[${index}].errorImplements`, '')
    ///// verificar se é exigido maquinário
    const params = {
      op_name: values.main_operation.name,
      t_name: value,
    };
    const result = await checkEventTransportHasMachinery(params);

    if (result.payload) {
      setFieldValue(`transports[${index}].hasMachineryTransports`, result.payload);
      const params1 = {
        user_id: user.user_icv_id,
        prop_id: property._id,
        op_name: values.main_operation.name,
        t_name: value,
      };
      const result1 = await fetchEnumerationEventGenericTransportMachinery(params1);
      if(!!result1.payload.detail){
        setFieldValue(`transports[${index}].errorMachinery`, result1.payload)
        //setFieldValue(`transports[${index}].isMachinery`, false)
      } else {
        setFieldValue(`transports[${index}].machineryList`, result1.payload || [])
        const newItem = result1.payload
        let atual = machineryTransportList.slice()
        const newItems = [ ...atual, newItem ]
        setMachineryTransportList(newItems)
        //setFieldValue(`transports[${index}].isMachinery`, true)
      }
    } else {
      //setFieldValue(`transports[${index}].isMachinery`, false)
      setFieldValue(`transports[${index}].hasMachineryTransports`, false);
    }
  };

  const handleChangeApplicationType = async (event, values, setFieldValue, index) => {
    const type = event.target.value;
    setFieldValue(`applications[${index}].type`, type);
    const params = {
      user_id: user.user_icv_id,
      prop_id: property._id,
      type_: type,
    };
    const result = await fetchEnumerationEventMainOperationProducts(params);
    setFieldValue(`applications[${index}].productList`, result.payload);

    if(Array.isArray(productSelected)){
      let newProductSelected = productSelected.slice() || []
      setProductSelected(...newProductSelected, result.payload);
    }
    //// Pesticide Foliar interception
    if (event.target.value === "pesticide") {
      const params = {
        crop: cropName,
      };
      const resultFoliar = await fetchEnumerationPesticideFoliarInterception(params);
      setFieldValue(`applications[${index}].foliarInterceptionList`, resultFoliar.payload);

      const resultMethods = await fetchEnumerationPesticideApplicationMethods(params);
      setFieldValue(`applications[${index}].applicationMethodList`, resultMethods.payload);
    }
  };

  const handleChangeTransportsImplements = (event, setFieldValue, value, index) => {
    const impl = value.map((el) => el);
    setFieldValue(`transports[${index}].machinery[0].implements`, impl);
  };

  const handleSubmit = async (values, resetForm, setFieldError) => {
    let validado = true;
    const ident = {
      user_id: user.user_icv_id,
      prop_id: property._id,
      plot_ter_id: plot_terrain._id,
      plot_id: plot._id,
      crop: cropName,
      event_id: values._id,
    };

    //// Operations machinery
    let operationsMachinery = [];

    if (values.main_operation.machinery) {
      if (
        values.main_operation.machinery.length !== 0 &&
        values.main_operation.machinery[0]._id !== ""
      ) {
        operationsMachinery = [
          {
            machinery_id: values.main_operation.machinery[0]._id || '',
            implements: values.main_operation.machinery[0].implements.map((el) => 
              {
                if(typeof el === 'object') {
                  return el._id
                } else {
                  return el
                }
              }
            )
          },
        ];
      }
    }

    //// Transports
    let transportsArray = [];
    values.transports &&
      values.transports.map((el, index) => {
        let ta = {
          _id: el._id || uuidv4(),
          type: el.type || 'generic',
          name: el.name,
        };
        if (el.hasMachineryTransports) {
          ta = {
            ...ta,
            machinery: el.machinery[0]
              ? [
                  {
                    machinery_id: el.machinery[0]._id,
                    implements: el.machinery[0].implements ? el.machinery[0].implements.map(item => {
                      if(item._id)
                        return item._id
                      return item
                    }) : [],
                  },
                ]
              : [],
          };
          transportsArray.push(ta);
        }
        return true;
      });
    let params = {};
    let applicationsArray = [];

    //// Applications
    if (values.applications) {
      values.applications.map((el, index) =>
          el.type !== "" && 
          applicationsArray.push({
            _id: el._id,
            type: el.type,
            amount: el.amount,
            amount_unit: el.amount_unit,
            product: typeof el.product === "string" ? el.product : el.product._id,
            foliar_interception: el.foliar_interception === "" ? null : el.foliar_interception,
            fi_amount: el.fi_amount === "" ? null : el.fi_amount,
            application_method: el.application_method === "" ? null : el.application_method,
          })
      );
    }

    if (values.type === "application" &&  values.main_operation.type === "from ecoinvent") {
      params = {
        ...ident,
        payload: {
          type: values.type,
          date: values.date,
          main_operation: {
            type: values.main_operation.type,
            ecoinvent: values.main_operation.ecoinvent,
            amount: values.main_operation.amount,
          },
          applications: applicationsArray,
        },
      };
    }
    if (values.type === "application" && values.main_operation.type === "generic") {
      if(transportsArray.length > 0){
        params = {
          ...ident,
          payload: {
            type: values.type,
            date: values.date,
            main_operation: {
              _id: values.main_operation._id,
              type: values.main_operation.type,
              name: values.main_operation.name,
              machinery: operationsMachinery,
            },
            transports: transportsArray,
            applications: applicationsArray,
          },
        }
      } else {
        params = {
          ...ident,
          payload: {
            type: values.type,
            date: values.date,
            main_operation: {
              _id: values.main_operation._id,
              type: values.main_operation.type,
              name: values.main_operation.name,
              machinery: operationsMachinery,
            },
            applications: applicationsArray,
          },
        }
      };
    }
    if (values.type === "burning" && values.main_operation.type === "from ecoinvent") {
      params = {
        ...ident,
        payload: {
          type: values.type,
          date: values.date,
          area_burnt: values.area_burnt,
          comb_fuel: values.comb_fuel,
          main_operation: {
            type: values.main_operation.type,
            ecoinvent: values.main_operation.ecoinvent,
            amount: values.main_operation.amount,
          },
        },
      };
    }
    if (values.type === "burning" && values.main_operation.type === "generic") {
      params = {
        ...ident,
        payload: {
          type: values.type,
          date: values.date,
          area_burnt: values.area_burnt,
          comb_fuel: values.comb_fuel,
          main_operation: {
            type: values.main_operation.type,
            name: values.main_operation.name,
            machinery: operationsMachinery,
          },
        },
      };
    }
    if (values.type === "generic" && values.main_operation.type === "from ecoinvent") {
      params = {
        ...ident,
        payload: {
          type: values.type,
          date: values.date,
          main_operation: {
            type: values.main_operation.type,
            ecoinvent: values.main_operation.ecoinvent,
            amount: values.main_operation.amount,
          },
        },
      };
    }
    if (values.type === "generic" && values.main_operation.type === "generic") {
      params = {
        ...ident,
        payload: {
          type: values.type,
          date: values.date,
          main_operation: {
            type: values.main_operation.type,
            name: values.main_operation.name,
            machinery: operationsMachinery,
          },
          //transports: transportsArray,
        },
      };
      if (transportsArray.length > 0) {
        params.payload = { ...params.payload, transports: transportsArray };
      }
    }
    if (values.type === "irrigation, generic" && values.main_operation.type === "from ecoinvent") {
      params = {
        ...ident,
        payload: {
          type: values.type,
          date: values.date,
          main_operation: {
            type: values.main_operation.type,
            ecoinvent: values.main_operation.ecoinvent,
            amount: values.main_operation.amount,
          },
          applications: applicationsArray,
        },
      };
    }
    if (values.type === "irrigation, generic" && values.main_operation.type === "generic") {
      if(transportsArray.length > 0){
        params = {
          ...ident,
          payload: {
            type: values.type,
            date: values.date,
            main_operation: {
              type: values.main_operation.type,
              name: values.main_operation.name,
              machinery: operationsMachinery,
            },
            applications: applicationsArray,
            transports: transportsArray,
          },
        };
      } else {
        params = {
          ...ident,
          payload: {
            type: values.type,
            date: values.date,
            main_operation: {
              type: values.main_operation.type,
              name: values.main_operation.name,
              machinery: operationsMachinery,
            },
            applications: applicationsArray,
          },
        };
      }
      
    }
    if (values.type === "irrigation, periodic" && values.main_operation.type === "from ecoinvent") {
      params = {
        ...ident,
        payload: {
          type: values.type,
          date: values.date,
          frequency: parseInt(values.frequency),
          main_operation: {
            type: values.main_operation.type,
            ecoinvent: values.main_operation.ecoinvent,
            amount: values.main_operation.amount,
          },
          applications: applicationsArray,
        },
      };
    }
    if (values.type === "irrigation, periodic" && values.main_operation.type === "generic") {
      if(transportsArray.length > 0){
        params = {
          ...ident,
          payload: {
            type: values.type,
            date: values.date,
            frequency: parseInt(values.frequency),
            main_operation: {
              type: values.main_operation.type,
              name: values.main_operation.name,
              machinery: operationsMachinery,
            },
            applications: applicationsArray,
            transports: transportsArray,
          },
        };
      } else {
        params = {
          ...ident,
          payload: {
            type: values.type,
            date: values.date,
            frequency: parseInt(values.frequency),
            main_operation: {
              type: values.main_operation.type,
              name: values.main_operation.name,
              machinery: operationsMachinery,
            },
            applications: applicationsArray,
          },
        };
      }
    }
    if (validado) {
      await editPlotEvent(params);
      resetForm();
      setInitialValues({});
      handleClose();
      await fetchUserAll(user.user_icv_id);
    }
  };

  const handleChangeFoliarInterception = (e, index, setFieldValue) => {
    setFieldValue(`applications[${index}].foliar_interception`, e.target.value);
    setFieldValue(`applications[${index}].application_method`, "");
    setFieldValue(`applications[${index}].fi_amount`, "");
  };

  const handleChangeDate = async (e, setFieldValue, setFieldError) => {
    const ev_date = e.target.value;
    setFieldValue("date", ev_date);
    //// Verificar conflito de data
    const event_date = ev_date.substring(8) + "-" + ev_date.substring(5, 7) + "-" + ev_date.substring(0, 4);
    const params = {
      event_date: event_date,
      plot_id: plot._id,
    };
    const result = await checkEventDateIsNotConflicted(params);
    if (result.payload?.error) {
      setFieldError("date", result.payload.message.detail);
    }
  };

  const handleOpenMachineryForm = async (setFieldValue, values) => {
    setOrigin("addEvent")
    await setOpenMachineryForm(true);
  };

  useEffect(() => {
    setTimeout(() => {
      const dialog = document.querySelector('.dialog')
      if(dialog){
        dialog.scrollIntoView({behavior: 'smooth', block: 'start'})
      }
      setLoading(false)
      }, 1500);
  }, [initialValues])

  const handleOpenImplementForm = async (setFieldValue, values) => {
    setOperationMachineryTypeSelected(values.main_operation.machinery[0].specific_type)
    setOperationNameSelected(values.main_operation.name)
    setOrigin('editEvent')
    await setOpenImplementForm(true);
  };

  const handleOpenMachineryFormTransport = async (setFieldValue, values, index) => {
    setOrigin("addEventTransport")
    setIndexAddMachinery(index)
    await setOpenMachineryForm(true);
  };

  const handleOpenImplementFormTransport = async (setFieldValue, values, index) => {
    setOrigin('addEventTransport')
    setIndexAddImplement(index)
    await setOpenImplementForm(true);
  };

  const handleChangeTransportsMachinery1 = async (event, setFieldValue, index, values) => {

    if(event.target.value !== ""){
      await setFieldValue(`transports[${index}].machinery[0]._id`, event.target.value)
      await setFieldValue(`transports[${index}].isMachinery`, true)
      let listAtual = implementsTransportList
      const machinery_specific_type = values.transports[index].machineryList && values.transports[index].machineryList.find(el => el._id === event.target.value).specific_type
      //const machinery_type = values.transports[index].machineryList && values.transports[index].machineryList.find(el => el._id === event.target.value).type
      let implementsList = null
      if(machinery_specific_type){
        implementsList = await fetchEnumerationEventGenericTransportImplements({
          user_id: user.user_icv_id,
          prop_id: property._id,
          op_name: values.main_operation.name,
          t_name: values.transports[index].name,
          mach_spectype: machinery_specific_type || null,
        }).then(res => res.payload)
      } else {
        implementsList = null
      }
      listAtual.splice(index, 1, implementsList)

      setImplementsTransportList(listAtual)

      setFieldValue(`transports[${index}].machinery.implements`, [])
      setUpdate(update + 1)
    } 
  }

  const handleAddItemApplication = async (values, setFieldValue) => {
    const params = {
      op_name: values.main_operation.name || values.main_operation.ecoinvent,
      payload: {
        prod_list: "",
      },
    };

    const index = values.applications.length
    const result = await fetchEnumerationEventApplicationType(params);
    setFieldValue(`applications[${index}].typeList`, result.payload);
    
    // Pesticide Foliar interception
    if (values.application && values.application[index].type === "pesticide") {
      const params = {
        crop: cropName,
      };
      const resultFoliar = await fetchEnumerationPesticideFoliarInterception(params);
      setFieldValue(`applications[${index}].foliarInterceptionList`, resultFoliar.payload);

      const resultMethods = await fetchEnumerationPesticideApplicationMethods(params);
      setFieldValue(`applications[${index}].applicationMethodList`, resultMethods.payload);
    }
    
    setIndexAddApplication(index)
  };


  return (
    <div>
      {
        loading || loadingForm 
        ? (
          <Backdrop open={true} sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <CircularProgress color="secondary" />
          </Backdrop>
        ) 
        : null
      }

      <Dialog
        open={openPlotEventFormEdit}
        onClose={handleClose}
        maxWidth="md"
        scroll="body"
      >
        <DialogTitle
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          className='dialog'
        >
          Edit Plot Activity Event
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{position: "absolute", right: 8, top: 8, color: "green",}}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <Typography variant="body2" sx={{ ml: 3, mt: 1, color: "red", fontWeight: "bold" }}>
          * Required Fields
        </Typography>

        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          enableReinitialize={renderForm}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm, setFieldError }) => {
            handleSubmit(values, resetForm, setFieldError);
          }}
        >
          {({values, touched, errors, handleChange, setFieldValue, setFieldError, isValid,}) => {
            return (
              <Form noValidate autoComplete="off">
                {
                  loadingForm 
                  ? (
                    <Backdrop open={true} sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1,}}>
                      <CircularProgress color="secondary" />
                    </Backdrop>
                  ) 
                  : null
                }

                <DialogContent sx={{ minWidth: { lg: "900px", md: "800px", sm: "300px" } }}>
                  <Grid container spacing={2}>

                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <TextField
                        disabled
                        required
                        fullWidth
                        size="small"
                        name="type"
                        label="Type"
                        value={values.type || eventType}
                      />
                    </Grid>

                    {
                      values.type !== "" 
                      ? (
                        <>
                          <Grid item xs={12} sm={6} md={4} lg={4}>
                            <TextField
                              required
                              fullWidth
                              size="small"
                              name="date"
                              label="Date"
                              type="date"
                              value={values.date || ""}
                              onChange={(e) => handleChangeDate(e, setFieldValue, setFieldError)}
                              InputLabelProps={{ shrink: true }}
                              error={Boolean(errors?.date)}
                              helperText={errors?.date}
                              inputProps={{ min: startDate,  max: endDate }}
                            />
                          </Grid>

                          {
                            values.type === "burning" 
                            ? (
                              <>
                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                  <FormControl fullWidth variant="outlined">
                                    <InputLabel size="small" htmlFor="area_burnt">
                                      Burnt Area
                                    </InputLabel>
                                    <OutlinedInput
                                      fullWidth
                                      size="small"
                                      name="area_burnt"
                                      id="area_burnt"
                                      label="Burnt Area"
                                      value={values.area_burnt ? values.area_burnt.toString(): ""}
                                      onChange={handleChange}
                                      onKeyDown={numericOnly}
                                      error={touched.area_burnt && Boolean(errors.area_burnt)}
                                      inputProps={{ step: 0.01, min: 0 }}
                                      type="number"
                                      endAdornment={
                                        <InputAdornment position="end">
                                          <Box mr={1}>[ha]</Box>
                                          <PopoverCustom info={ "Area in which the burning occurred"}/>
                                        </InputAdornment>
                                      }
                                    />
                                  </FormControl>
                                  {
                                    touched.area_burnt && errors.area_burnt && (
                                      <FormHelperText error>
                                        {errors.area_burnt}
                                      </FormHelperText>
                                    )
                                  }
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                  <FormControl fullWidth variant="outlined">
                                    <InputLabel size="small" htmlFor="comb_fuel">
                                      Biomass Fuel Burnt
                                    </InputLabel>
                                    <OutlinedInput
                                      fullWidth
                                      size="small"
                                      name="comb_fuel"
                                      id="comb_fuel"
                                      label="Biomass Fuel Burnt"
                                      value={values.comb_fuel ? values.comb_fuel.toString() : ""}
                                      onChange={handleChange}
                                      onKeyDown={numericOnly}
                                      error={ touched.comb_fuel && Boolean(errors.comb_fuel)}
                                      inputProps={{ step: 0.01, min: 0 }}
                                      type="number"
                                      endAdornment={
                                        <InputAdornment position="end">
                                          <Box mr={1}>[kg / ha]</Box>
                                        </InputAdornment>
                                      }
                                    />
                                  </FormControl>
                                  {
                                    touched.comb_fuel && errors.comb_fuel && (
                                      <FormHelperText error>
                                        {errors.comb_fuel}
                                      </FormHelperText>
                                    )
                                  }
                                </Grid>
                              </>
                            ) 
                            : null
                          }

                          {
                            values.type === "irrigation, periodic" 
                            ? (
                              <Grid item xs={12} sm={6} md={4} lg={4}>
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel size="small" htmlFor="frequency">
                                    Frequency of application
                                  </InputLabel>
                                  <OutlinedInput
                                    fullWidth
                                    size="small"
                                    name="frequency"
                                    id="frequency"
                                    label="Frequency of application"
                                    value={values.frequency ? values.frequency : ""}
                                    onChange={handleChange}
                                    onKeyDown={numericOnly}
                                    error={touched.frequency && Boolean(errors.frequency)}
                                    inputProps={{ step: 1, min: 1 }}
                                    type="number"
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <Box sx={{ whiteSpace: "normal",lineHeight: "1.2",}}>
                                          [days]
                                        </Box>
                                      </InputAdornment>
                                    }
                                  />
                                </FormControl>
                                {
                                  touched.frequency && errors.frequency && (
                                    <FormHelperText error>
                                      {errors.frequency}
                                    </FormHelperText>
                                  )
                                }
                              </Grid>
                            ) 
                            : null
                          }

                          <Grid item xs={12} display={"flex"} alignItems="center" gap={2}
                            sx={{
                              backgroundColor: "#F5F5F5",
                              paddingBottom: 2,
                              marginLeft: 2,
                              marginTop: 1,
                              borderRadius: 3,
                            }}
                          >
                            <Typography variant="subtitle" sx={{ fontWeight: "bold" }}>
                              Main Operation
                            </Typography>

                            <PopoverCustom info={"Operations that happened in the event. Must have one main operation and may have other transport.main_operation."}/>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <TextField
                              disabled
                              required
                              fullWidth
                              size="small"
                              name={"values.main_operation.type"}
                              label="Type"
                              value={values.main_operation?.type || ""}
                            />
                          </Grid>

                          {
                            values.main_operation?.type === "generic" && (
                              <>
                                <Grid item xs={12} sm={6} md={4} lg={5}>
                                  <TextField
                                    disabled
                                    required
                                    fullWidth
                                    size="small"
                                    name="main_operation.name"
                                    label="Name"
                                    value={values.main_operation.name}
                                  />
                                </Grid>



                                {
                                  hasMachinery 
                                  ? (
                                    <Grid item xs={12} sm={6} md={4} lg={4} sx={{ mb: 1 }}>
                                      <TextField
                                        required
                                        fullWidth
                                        size="small"
                                        name="main_operation.machinery[0]._id"
                                        label="Machinery"
                                        value={values.main_operation.machinery[0]._id || ''}
                                        select
                                        onChange={(event) =>{
                                          handleChange(event) 
                                          handleChangeOperationMachinery(event, setFieldValue, values)
                                        }}
                                        InputProps={{
                                          endAdornment: (
                                            <Box sx={{ mr: 3 }}>
                                              <PopoverCustom info={"Machinery used in the operation"} />
                                            </Box>
                                          ),
                                        }}
                                      >
                                        <MenuItem key={0} value={""}>
                                          <Button
                                            fullWidth
                                            size='small'
                                            color="primary"
                                            variant="contained"
                                            component="label"
                                            onClick={() => handleOpenMachineryForm(setFieldValue, values)}
                                            sx={{fontSize: 13, textTransform: 'none'}}
                                            startIcon={<AddCircleOutlineRoundedIcon sx={{fontSize: 15, mr: 1,}}/>}
                                          >
                                            Add machinery
                                          </Button>
                                        </MenuItem>
                                        <Divider sx={{ my: 2, border: '1px solid green' }} />
                                        {
                                          eventOperationMachinery.registros.data 
                                          && eventOperationMachinery.registros.data.length > 0 
                                          ? (
                                            eventOperationMachinery.registros.data
                                            .slice()
                                            .sort((a, b) => (a.machine_name.toUpperCase() > b.machine_name.toUpperCase() ? 1 : -1))
                                            .map((option, index) => (
                                                <MenuItem key={index} value={option._id}>
                                                  {option.machine_name || ""}
                                                </MenuItem>
                                              )
                                            )
                                          ) 
                                          : (
                                            <MenuItem key={"no_options"} value={""} disabled>
                                              {"No options"}
                                            </MenuItem>
                                          )
                                        }
                                      </TextField>
                                      { 
                                        eventOperationMachinery.registros.error
                                        ? (
                                          <FormHelperText error sx={{ml:1.75}}>
                                            {eventOperationMachinery.registros.error }
                                          </FormHelperText>
                                        ) : null 
                                      }
                                      { 
                                        !eventOperationMachinery.registros.data
                                        && typeof eventOperationMachinery.error === 'string'
                                        ? (
                                          <FormHelperText error sx={{ml:1.75}}>
                                            {eventOperationMachinery.error }
                                          </FormHelperText>
                                        ) : null 
                                      }
                                    </Grid>
                                  ) 
                                  : null
                                }

                                {
                                  hasMachinery 
                                  && !eventOperationMachineryImplements.registros.detail 
                                  && eventOperationMachineryImplements.registros.length > 0 
                                  && values.main_operation.machinery[0]._id 
                                  ? (
                                    <Grid item xs={12} sx={{mb:1}}>
                                      <Autocomplete
                                        multiple
                                        id={`main_operation.machinery[0].implements`}
                                        name={`main_operation.machinery[0].implements`}
                                        options={
                                          eventOperationMachineryImplements.registros
                                          .slice()
                                          .sort((a,b)=> a.implement_name.toUpperCase() < b.implement_name.toUpperCase() ? -1: 1) || []
                                        }
                                        getOptionLabel={(option) => {
                                          if(typeof option === 'string'){
                                            const implement = eventOperationMachineryImplements.registros.filter((el) => el._id === option)
                                            return implement[0]?.implement_name
                                          } else {
                                            return option?.implement_name
                                          }
                                        }}
                                        filterSelectedOptions
                                        onChange={(event, value) => { handleChangeImplements(event, setFieldValue, value);}}
                                        value={ values.main_operation.machinery[0].implements || null }
                                        isOptionEqualToValue={(option, value) => {
                                          if(typeof value === "string"){
                                            return option._id === value
                                          } else {
                                            return option._id === value._id
                                          }
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label="Implements"
                                            placeholder="Implements"
                                            InputProps={{
                                              ...params.InputProps,
                                              endAdornment: (
                                                <>
                                                  <InputAdornment position="end"  sx={{textAlign:'center'}}>
                                                    <PopoverCustom
                                                      info={"Implements used with the machinery, can be empty in case no implement is used"}
                                                    />
                                                  </InputAdornment>
                                                  {params.InputProps.endAdornment}
                                                </>
                                              ),
                                            }}
                                          />
                                        )}
                                      />
                                      
                                    </Grid>
                                    ) 
                                  : (
                                    <Grid item xs={12} mt={-4}>
                                      {
                                        eventOperationMachineryImplements.registros.detail && (
                                          <FormHelperText error>
                                            {
                                              typeof eventOperationMachineryImplements ==="object"
                                              ? eventOperationMachineryImplements.registros.detail[0].msg
                                              : eventOperationMachineryImplements.registros.detail
                                            }
                                          </FormHelperText>
                                        )
                                      }
                                    </Grid>
                                  )
                                }
                                <Button
                                  size='small'
                                  color="primary"
                                  variant='contained'
                                  component="label"
                                  onClick={() => handleOpenImplementForm(setFieldValue, values)}
                                  sx={{fontSize: 13, textTransform: 'none', mr:2, ml:2 }}
                                  startIcon={<AddCircleOutlineRoundedIcon sx={{fontSize: 15, mr: 1,}}/>}
                                >
                                  Add implement
                                </Button>
                              </>
                            )
                          }
                          {
                            values.main_operation?.type === "from ecoinvent" && (
                              <>
                                <Grid item xs={12} sm={6} md={4} lg={6}>
                                  <TextField
                                    disabled
                                    required
                                    fullWidth
                                    size="small"
                                    name="main_operation.ecoinvent`"
                                    label="Name (ecoinvent)"
                                    value={values.main_operation.ecoinvent}
                                  />
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                  <FormControl fullWidth variant="outlined">
                                    <InputLabel required size="small" htmlFor="amount">
                                      Amount
                                    </InputLabel>
                                    <OutlinedInput
                                      required
                                      fullWidth
                                      size="small"
                                      name={`main_operation.amount`}
                                      id="amount"
                                      label="Amount"
                                      value={values.main_operation.amount}
                                      onChange={handleChange}
                                      onKeyDown={numericOnly}
                                      inputProps={{ step: 0.01, min: 0 }}
                                      type="number"
                                      error={touched.main_operation?.amount && Boolean(errors.main_operation?.amount)}
                                      endAdornment={
                                        <InputAdornment position="end">
                                          <Box> {ecoinventAmountUnit === " m3" ? "m³" : ecoinventAmountUnit}</Box>
                                          <PopoverCustom info={"Amount of area where the agricultural operation was carried out"}/>
                                        </InputAdornment>
                                      }
                                    />
                                    {
                                      touched.main_operation?.amount && errors.main_operation?.amount && (
                                        <FormHelperText error>
                                          {errors.main_operation?.amount}
                                        </FormHelperText>
                                      )
                                    }
                                  </FormControl>
                                </Grid>
                              </>
                            )
                          }

                          {
                            values.main_operation?.type === "generic" 
                            && eventGenericHasTransport.registro && (
                              <>
                                <Grid item xs={12} display={"flex"} alignItems="center" gap={1}
                                  sx={{
                                    backgroundColor: "#F5F5F5",
                                    paddingBottom: 2,
                                    marginLeft: 2,
                                    marginTop: 1,
                                    borderRadius: 3,
                                  }}
                                >
                                  <Typography variant="subtitle" sx={{ fontWeight: "bold" }}>
                                    Additional Transports
                                  </Typography>
                                </Grid>

                                <FieldArray
                                  name="transports"
                                  render={(arrayHelpers) => (
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                      <>
                                        {
                                          values.transports 
                                          && values.transports.length > 0
                                          ? (
                                              values.transports.map((item, index) => (
                                                <Box key={index}>
                                                  <Grid container sx={{mb:0}} spacing={1}>
                                                    <Grid item xs={11} sm={3} md={2} lg={2}>
                                                      <TextField
                                                        fullWidth
                                                        size="small"
                                                        name={`transports[${index}].type`}
                                                        label="Type"
                                                        value={values.transports[index].type === "" ? "generic": values.transports[index].type}
                                                        onChange={handleChange}
                                                        disabled
                                                      />
                                                    </Grid>
                                                    
                                                    <Grid item xs={12} sm={6} md={5} lg={5} sx={{ mb: 2 }}>
                                                      <Autocomplete
                                                        disabled={!!values.transports[index].name}
                                                        size="small"
                                                        id={`transports[${index}].name`}
                                                        name={`transports[${index}].name`}
                                                        options={eventGenericTransportType.registros || []}
                                                        onChange={(event,value) => handleChangeTransportsName(event, index, setFieldValue, values, value)}
                                                        value={values.transports[index].name || null}
                                                        renderInput={( params ) => (
                                                          <TextField
                                                            {...params}
                                                            autoFocus
                                                            label="Name"
                                                          />
                                                        )}
                                                      />
                                                    </Grid>

                                                    {
                                                      values.transports[index].hasMachineryTransports 
                                                      ? (
                                                        <Grid item xs={12} sm={6} md={4} lg={4}>
                                                          <TextField
                                                            fullWidth
                                                            size="small"
                                                            name={`transports[${index}].machinery[0]._id`}
                                                            select
                                                            label="Machinery"
                                                            value={values.transports[index].machinery[0]?._id || ''}
                                                            onChange={(event) => { handleChangeTransportsMachinery1(event, setFieldValue, index, values)}} 
                                                            InputProps={{
                                                              endAdornment: (
                                                                <Box sx={{ mr: 3 }}>
                                                                  <PopoverCustom info={"Machinery used in the operation"}/>
                                                                </Box>
                                                              ),
                                                            }}
                                                            error={!values.transports[index].isMachinery}
                                                            helperText={
                                                              !values.transports[index].isMachinery
                                                              ? ('Required field')
                                                              : null
                                                            }
                                                            
                                                          >
                                                            <MenuItem key={"no_options"} value={""}>
                                                              <Box display={"flex"} justifyContent={"space-between"} gap={2} width={"100%"}>
                                                                <Button
                                                                  fullWidth
                                                                  size='small'
                                                                  color="primary"
                                                                  variant='contained'
                                                                  component="label"
                                                                  onClick={() => handleOpenMachineryFormTransport(setFieldValue, values, index)}
                                                                  sx={{fontSize: 13, textTransform: 'none'}}
                                                                  startIcon={<AddCircleOutlineRoundedIcon sx={{fontSize: 15, }}/>}
                                                                >
                                                                  Add machinery
                                                                </Button>
                                                              </Box>
                                                            </MenuItem>

                                                            <Divider sx={{ my: 2, border: '1px solid green' }} />
                                                            {
                                                              values.transports[index].machineryList.length > 0
                                                              && values.transports[index].machineryList
                                                              .slice()
                                                              .sort((a,b) => (a.machine_name.toUpperCase() > b.machine_name.toUpperCase()) ? 1 : -1)
                                                              .map((option, index) => (
                                                                <MenuItem key={index} value={option._id}>
                                                                  {option.machine_name || ""}
                                                                </MenuItem>
                                                              ))
                                                            }
                                                          </TextField>

                                                          {
                                                            !!eventGenericTransportMachinery.registros?.error && (
                                                              <Typography component="div" variant="body2" color="red">
                                                                { eventGenericTransportMachinery.registros?.error }
                                                              </Typography>
                                                            )
                                                          }
                                                          <Box> { eventGenericTransportMachinery.error.msg } </Box>
                                                        </Grid>
                                                      ) 
                                                      : null
                                                    }

                                                    <Grid item xxs={12} xs={12} sm={5} md={1}>
                                                      <Button type="button" onClick={() => {
                                                          arrayHelpers.remove(index)
                                                          if(index >= 0){
                                                            implementsTransportList.splice(index, 1)
                                                          }
                                                        }}
                                                      >
                                                        <RemoveCircleOutlineRoundedIcon />
                                                      </Button>
                                                    </Grid>

                                                    { implementsTransportList[index]
                                                      && implementsTransportList[index].length > 0 
                                                      ? (
                                                        
                                                        <Grid item xs={11}>
                                                            <Autocomplete
                                                              multiple
                                                              id={`transports[${index}].machinery[0].implements`}
                                                              name={`transports[${index}].machinery[0].implements`}
                                                              options={
                                                                implementsTransportList[index]
                                                                    .slice()
                                                                    .sort((a,b)=> a.implement_name.toUpperCase() > b.implement_name.toUpperCase() ? 1: -1) 
                                                                || []
                                                              }

                                                              getOptionLabel={(option) => {
                                                                if(typeof option === 'string'){
                                                                  const implement = implementsTransportList[index].filter((el) => el._id === option)
                                                                  return implement[0]?.implement_name
                                                                } else {
                                                                  return option?.implement_name
                                                                }
                                                              }}
                                                              filterSelectedOptions
                                                              value={ values.transports[index].machinery[0].implements || []}
                                                              onChange={(event, value) => {handleChangeTransportsImplements(event,setFieldValue,value, index)}}
                                                              isOptionEqualToValue={(option, value) => {
                                                                if(typeof value === "string"){
                                                                  return option._id === value
                                                                } else {
                                                                  return option._id === value._id
                                                                }
                                                              }}
                                                              renderInput={(params) => (
                                                                <TextField
                                                                  {...params}
                                                                  label="Implements"
                                                                  placeholder="Implements"
                                                                  InputProps={{
                                                                    ...params.InputProps,
                                                                    endAdornment: (
                                                                      <>
                                                                        <InputAdornment position="end">
                                                                          <PopoverCustom
                                                                            info={"Implements used with the machinery, can be empty in case no implement is used"}
                                                                          />
                                                                        </InputAdornment>
                                                                        {params.InputProps.endAdornment}
                                                                      </>
                                                                    ),
                                                                  }}
                                                                />
                                                              )}
                                                            />
                                                            <Button
                                                              size='small'
                                                              color="primary"
                                                              variant='contained'
                                                              component="label"
                                                              onClick={() => handleOpenImplementFormTransport(setFieldValue, values, index)}
                                                              sx={{fontSize: 13, textTransform: 'none', mt:1, mr:2}}
                                                              startIcon={<AddCircleOutlineRoundedIcon sx={{fontSize: 15, mr: 1,}}/>}
                                                            >
                                                              Add implement
                                                            </Button>
                                                          </Grid>
                                                        
                                                        
                                                        )
                                                      : implementsTransportList[index]?.implementsList?.detail
                                                        ? (
                                                          <FormHelperText error component='div' sx={{ml:1}}>
                                                            <Box>Implements:<span> { implementsTransportList[index].implementsList.detail }</span></Box>
                                                            <Box display='flex' gap={2} mt={1}>
                                                              <Button
                                                                size='small'
                                                                color="primary"
                                                                variant='contained'
                                                                component="label"
                                                                onClick={() => handleOpenImplementForm(setFieldValue, values)}
                                                                sx={{fontSize: 13, textTransform: 'none', }}
                                                                startIcon={<AddCircleOutlineRoundedIcon sx={{fontSize: 15, mr: 1,}}/>}
                                                              >
                                                                Add implement
                                                              </Button>
                                                            </Box>
                                                          </FormHelperText>
                                                      )
                                                        : null
                                                    }

                                                  </Grid>
                                                  <Divider sx={{ my: 2, border: '1px solid green' }} />      
                                                </Box>
                                              ))
                                            )
                                          : null
                                        }
                                        <Box sx={{mt:2}}>
                                          <Button
                                            type="button"
                                            variant="outlined"
                                            onClick={() =>
                                              arrayHelpers.push({
                                                _id: uuidv4(),
                                                type: "generic",
                                                name: "",
                                                machineryList: [],
                                                machinery: [
                                                  {
                                                    _id: "",
                                                    implements: [],
                                                  }
                                                ],
                                              })
                                            }
                                            startIcon={
                                              <AddCircleOutlineRoundedIcon />
                                            }
                                          >
                                            Add Additional Transports
                                          </Button>
                                        </Box>
                                      </>
                                    </Grid>
                                  )}
                                />
                              </>
                            )
                          }

                          {
                            (values.type === "application" || values.type === "irrigation, generic" ||  values.type === "irrigation, periodic") 
                            && (values.main_operation.name || values.main_operation.ecoinvent) 
                            ? (
                              <>
                                <Grid
                                  item xs={12} display={"flex"} alignItems="center" gap={2}
                                  sx={{
                                    backgroundColor: "#F5F5F5",
                                    paddingBottom: 2,
                                    marginLeft: 2,
                                    marginTop: 1,
                                    borderRadius: 3,
                                  }}
                                >
                                  <Typography variant="subtitle" sx={{ fontWeight: "bold" }}>
                                    Applications
                                  </Typography>
                                  <PopoverCustom info={"Applications done in the context of the event."}/>
                                </Grid>

                                <FieldArray
                                  name="applications"
                                  render={(arrayHelpers) => (
                                    <>

                                      <Grid item xs={12} sm={12} md={12} lg={12}>
                                        {
                                          values.applications &&
                                          values.applications.length > 0
                                          ? values.applications.map((item, index) =>  (
                                            
                                            <Box key={index}>
                                              <Grid container spacing={2}>
                                                <Grid item xs={11} sm={6} md={3} lg={3}>
                                                  <TextField
                                                    disabled={Boolean(values.applications[index].type)}
                                                    required
                                                    fullWidth
                                                    size="small"
                                                    name={`applications[${index}].type`}
                                                    select
                                                    label="Type"
                                                    value={ values.applications[index].type }
                                                    onChange={(event) => {handleChangeApplicationType(event, values, setFieldValue, index);}}
                                                    error={typeof errors.applications !== "undefined" && Boolean(errors.applications[index]?.type)}
                                                    helperText={typeof errors.applications !=="undefined" && Boolean(errors.applications[index]?.type) && errors.applications[index]?.type}
                                                  >
                                                    <MenuItem key={'none'} value={""}>
                                                      {'none'}
                                                    </MenuItem>
                                                    {
                                                      typeApplication
                                                      && typeApplication.map((option) => (
                                                        <MenuItem key={option} value={option}>
                                                          {option}
                                                        </MenuItem>
                                                      ))
                                                    }
                                                  </TextField>
                                                </Grid>

                                                {
                                                  !!values.applications[index].type && (
                                                    <>
                                                      <Grid item xs={11} sm={5} md={4} lg={4}>
                                                        <TextField
                                                          disabled={Boolean(values.applications[index].product?._id)}
                                                          required
                                                          fullWidth
                                                          size="small"
                                                          name={`applications[${index}].product._id`}
                                                          select
                                                          label="Agricultural input"
                                                          //value={ values.applications[index].product}
                                                          value={values.applications[index].product?._id || ''}
                                                          onChange={(event, value, option) => {handleChangeApplicationsProduct(event, index, setFieldValue, value, values, option);}}
                                                          error={typeof errors.applications !== "undefined" && Boolean(errors.applications[index]?.product)}
                                                          helperText={typeof errors.applications !== "undefined" && Boolean(errors.applications[index]?.product) && errors.applications[index]?.product}
                                                        >
                                                          <MenuItem key={0} value={""}>
                                                            <Button
                                                              fullWidth
                                                              size='small'
                                                              color="primary"
                                                              variant='contained'
                                                              component="label"
                                                              onClick={() => handleOpenProductForm(setFieldValue, values, index)}
                                                              sx={{fontSize: 13, textTransform: 'none', mt:1}}
                                                              startIcon={<AddCircleOutlineRoundedIcon sx={{fontSize: 15, mr: 1,}}/>}
                                                            >
                                                              Add agricultural input
                                                            </Button>
                                                          </MenuItem>

                                                          <Divider sx={{ my: 2, border: '1px solid green' }} />
                                                            <MenuItem key={'none'} value={""}>
                                                              {'none'}
                                                            </MenuItem>
                                                            {
                                                              values.applications[index].productList
                                                              && values.applications[index].productList
                                                              .slice()
                                                              .sort((a, b)=> (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : -1)
                                                              .map((option) => (
                                                                <MenuItem key={option._id} value={option._id}>
                                                                  {option.name}
                                                                </MenuItem>
                                                              ))
                                                            }
                                                        </TextField>
                                                      </Grid>

                                                      <Grid item xs={11} sm={6} md={2} lg={2}>
                                                        <TextField
                                                          required
                                                          fullWidth
                                                          size="small"
                                                          name={`applications[${index}].amount`}
                                                          label="Amount"
                                                          value={values.applications[index].amount}
                                                          onChange={handleChange}
                                                          onKeyDown={numericOnly}
                                                          type="number"
                                                          error={typeof errors.applications !== "undefined" && Boolean(errors.applications[index]?.amount)}
                                                          helperText={typeof errors.applications !== "undefined" && Boolean(errors.applications[index]?.amount) && errors.applications[index]?.amount}
                                                          InputProps={{
                                                            endAdornment: (
                                                              <Box>
                                                                <PopoverCustom info={"Amount of applied agricultural inputs"}/>
                                                              </Box>
                                                            ),
                                                          }}
                                                        />
                                                      </Grid>
                                                      
                                                      <Grid item xs={11} sm={5} md={2} lg={2}>
                                                        <TextField
                                                          required
                                                          fullWidth
                                                          size="small"
                                                          name={`applications[${index}].amount_unit`}
                                                          select
                                                          label="Unit"
                                                          value={values?.applications[index].amount_unit ? values?.applications[index].amount_unit : ""}
                                                          onChange={handleChange}
                                                          error={typeof errors.applications !=="undefined" && Boolean(errors.applications[index]?.amount_unit)}
                                                          helperText={typeof errors.applications !=="undefined" && Boolean(errors.applications[index]?.amount_unit) && errors.applications[index]?.amount_unit}
                                                        >
                                                          <MenuItem key={'none'} value={""}>
                                                            {'none'}
                                                          </MenuItem>
                                                       
                                                          {
                                                            values.applications[index].unitList 
                                                            ? (
                                                                values.applications[index].unitList.map((option) => (
                                                                  <MenuItem key={option} value={option || ""}>
                                                                    {option}
                                                                  </MenuItem>
                                                                ))
                                                            ) 
                                                            : (
                                                              <MenuItem key={"no_option"} value={""}>
                                                                {"No options"}
                                                              </MenuItem>
                                                            )
                                                          }
                                                        </TextField>
                                                      </Grid>

                                                      {
                                                        values.applications[index].type === "pesticide" 
                                                        ? (
                                                          <>
                                                            <Grid item xs={11} sm={5} md={5} lg={5}>
                                                              <TextField
                                                                fullWidth
                                                                size="small"
                                                                name={`applications[${index}].foliar_interception`}
                                                                label="Foliar interception"
                                                                select
                                                                value={values.applications[index].foliar_interception || ""}
                                                                onChange={(e) => { handleChangeFoliarInterception(e, index, setFieldValue) }}
                                                                error={typeof errors.applications !== "undefined" && Boolean(errors.applications[index]?.foliar_interception)}
                                                                helperText={typeof errors.applications !== "undefined" && Boolean(errors.applications[index]?.foliar_interception) && errors.applications[index]?.foliar_interception}
                                                              >
                                                                <MenuItem key={'none'} value={""}>
                                                                  {'none'}
                                                                </MenuItem>

                                                                {
                                                                  values.applications[index].foliarInterceptionList
                                                                  && values.applications[index].foliarInterceptionList.map((option) => (
                                                                    <MenuItem key={option} value={ option || "" }>
                                                                      {option}
                                                                    </MenuItem>
                                                                  ))
                                                                }
                                                              </TextField>
                                                            </Grid>

                                                            <Grid item xs={11} sm={5} md={5} lg={5}>
                                                              <TextField
                                                                fullWidth
                                                                size="small"
                                                                name={`applications[${index}].application_method`}
                                                                label="Application method"
                                                                select
                                                                value={ values.applications[index].application_method || "" }
                                                                onChange={handleChange}
                                                                error={typeof errors.applications !=="undefined" && Boolean(errors.applications[index]?.application_method)}
                                                                helperText={typeof errors.applications !== "undefined" && Boolean(errors.applications[index]?.application_method) && errors.applications[index]?.application_method}
                                                              >
                                                                <MenuItem key={'none'} value={""}>
                                                                  {'none'}
                                                                </MenuItem>

                                                                {
                                                                  values.applications[index].applicationMethodList
                                                                  && values.applications[index].applicationMethodList.map((option) => (
                                                                    <MenuItem key={option} value={option || ""} sx={{maxWidth: '100%', textWrap: 'wrap', whiteSpace: 'normal'}}>
                                                                      {option}
                                                                    </MenuItem>
                                                                  ))
                                                                }
                                                              </TextField>
                                                            </Grid>

                                                            {
                                                              values.applications[index].foliar_interception === "Other" 
                                                              ? (
                                                                  <Grid item xs={11} sm={5} md={3} lg={3}>
                                                                    <TextField
                                                                      fullWidth
                                                                      size="small"
                                                                      name={`applications[${index}].fi_amount`}
                                                                      type="number"
                                                                      label="Foliar interception amount"
                                                                      inputProps={{ step: 0.01, min: 0, max: 1, }}
                                                                      value={ values.applications[index].fi_amount || ''}
                                                                      onChange={handleChange}
                                                                    />
                                                                  </Grid>
                                                              ) 
                                                              : null
                                                            }
                                                          </>
                                                        ) 
                                                        : null
                                                      }

                                                      
                                                    </>
                                                  )
                                                }
                                                <Grid item xs={1}>
                                                  <Button type="button"
                                                    onClick={() => {
                                                      arrayHelpers.remove(index);
                                                    }}
                                                  >
                                                    <RemoveCircleOutlineRoundedIcon />
                                                  </Button>
                                                </Grid>
                                              </Grid>
                                              
                                              <Divider sx={{ my: 2, border: '1px solid green' }} />
                                            </Box>
                                          ))
                                          : null
                                        }
                                      </Grid>
                                      
                                      <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Button
                                          type="button"
                                          variant="outlined"
                                          onClick={() => { 
                                            handleAddItemApplication(values, setFieldValue);
                                            arrayHelpers.push({
                                              type: "",
                                              typeList: values.applications[indexAddApplication]?.typeList || [],
                                              product: "",
                                              productList: [],
                                              amount: "",
                                              amount_unit: "",
                                              unitList: [],
                                            });
                                          }}
                                          startIcon={
                                            <AddCircleOutlineRoundedIcon />
                                          }
                                        >
                                          Add Application
                                        </Button>
                                      </Grid>

                                    </>
                                  )}
                                />
                              </>
                            ) 
                            : null
                          }
                        </>
                      ) 
                      : null
                    }
                    <Grid item xs={12} display={"flex"} gap={2}></Grid>
                  </Grid>
                </DialogContent>

                <DialogActions>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <Button
                      size="small"
                      onClick={handleClose}
                      variant="outlined"
                      startIcon={<CancelRoundedIcon />}
                      type="reset"
                    >
                      Cancel
                    </Button>

                    <Button
                      size="small"
                      variant="contained"
                      startIcon={<SaveRoundedIcon />}
                      autoFocus
                      type="submit"
                    >
                      Save
                    </Button>
                  </Box>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
      {
        openProductForm 
        ? (
          <ProductsForm
            openProductForm={openProductForm}
            setOpenProductForm={setOpenProductForm}
            user_id={user.user_icv_id}
            setProductSelected={setProductSelected}
            origin={origin}
          />
        ) : null
      }

      {
        openMachineryForm ? (
          <MachineryForm
            openMachineryForm={openMachineryForm}
            setOpenMachineryForm={setOpenMachineryForm}
            user_id={user.user_icv_id}
            property={property}
            machinerySelected={{}}
            mode='add'
            origin={origin}
            setMachinerySelected={setMachinerySelected}
            setMachineryTransportSelected={setMachineryTransportSelected}
          />
        ) : null
      }

      {
        openImplementForm ? (
          <ImplementForm 
            openImplementForm={openImplementForm}
            setOpenImplementForm={setOpenImplementForm}
            user_id={user.user_icv_id}
            mode={"add"}
            property={property}
            origin={origin}
            setImplementSelected={setImplementSelected}
            setImplementTransportSelected={setImplementTransportSelected}
            implementSelected={implementSelected}
          />
        ) : null
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userAll: {
      loading: state.userAll.loading,
      registro: state.userAll.registro,
      error: state.userAll.error,
    },
    eventTypeCrop: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationEventType,
      error: state.enumerated.error,
    },
    eventOperationType: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationEventOperationType,
      error: state.enumerated.error,
    },
    nameOperation: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationNameOperation,
      error: state.enumerated.error,
    },
    products: {
      loading: state.userAll.loading,
      registros: state.userAll.registro.user_products,
      error: state.userAll.error,
    },
    eventOperationMachinery: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationEventOperationMachinery,
      error: state.enumerated.error,
    },
    eventOperationMachineryImplements: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationEventOperationMachineryImplements,
      error: state.enumerated.error,
    },
    eventGenericTransportMachinery: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationEventGenericTransportMachinery,
      error: state.enumerated.error,
    },
    eventOperationEcoinvent: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationEventOperationEcoinvet,
      error: state.enumerated.error,
    },
    enumerationEventMainOperationProducts: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationEventMainOperationProducts,
      error: state.enumerated.error,
    },
    eventGenericHasTransport: {
      loading: state.enumerated.loading,
      registro: state.enumerated.enumerationEventGenericHasTransport,
      error: state.enumerated.error,
    },
    eventGenericTransportType: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationEventGenericTransportType,
      error: state.enumerated.error,
    },
    loadingEnumeration: state.enumerated.loading,
    eventForEdit: {
      loading: state.userAll.eventForEdit.loading,
      registro: state.userAll.eventForEdit.registro,
      error: state.userAll.eventForEdit.error,
    },
    eventGenericTransportImplements: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationEventGenericTransportImplements,
      error: state.enumerated.error,
    },
    eventMainOperationProducts:{
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationEventMainOperationProducts,
      error: state.enumerated.error,
    },
    eventApplicationUnit: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationEventApplicationUnit,
      error: state.enumerated.error,
    }
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEnumerationEventOperationType: (event_type) => dispatch(fetchEnumerationEventOperationType(event_type)),
    fetchEnumerationEventTypeCrop: (params) => dispatch(fetchEnumerationEventTypeCrop(params)),
    fetchEnumerationNameOperation: (params) => dispatch(fetchEnumerationNameOperation(params)),
    fetchEnumerationEventApplicationType: (params) => dispatch(fetchEnumerationEventApplicationType(params)),
    fetchEnumerationEventApplicationUnit: (prod_id) => dispatch(fetchEnumerationEventApplicationUnit(prod_id)),
    fetchEnumeracionEventOperationMachinery: (par) => dispatch(fetchEnumeracionEventOperationMachinery(par)),
    fetchEnumerationEventOperationMachineryImplements: (par) => dispatch(fetchEnumerationEventOperationMachineryImplements(par)),
    fetchEnumerationEventOperationEcoinvet: (params) => dispatch(fetchEnumerationEventOperationEcoinvet(params)),
    addPlotEvent: (params) => dispatch(addPlotEvent(params)),
    fetchEnumerationEventMainOperationProducts: (params) => dispatch(fetchEnumerationEventMainOperationProducts(params)),
    fetchEnumerationEventGenericHasTransport: (op_name) => dispatch(fetchEnumerationEventGenericHasTransport(op_name)),
    fetchEnumerationEventGenericTransportType: (op_name) => dispatch(fetchEnumerationEventGenericTransportType(op_name)),
    fetchEnumerationEventGenericTransportMachinery: (params) => dispatch(fetchEnumerationEventGenericTransportMachinery(params)),
    fetchEnumerationEventGenericTransportImplements: (params) => dispatch(fetchEnumerationEventGenericTransportImplements(params)),
    fetchUserAll: (user_id) => dispatch(fetchUserAll(user_id)),
    resetEnumerationEventOperationMachineryImplements: () => dispatch(resetEnumerationEventOperationMachineryImplements()),
    editPlotEvent: (params) => dispatch(editPlotEvent(params)),
    fetchEventForEdition: (params) => dispatch(fetchEventForEdition(params)),
    fetchEditEventData: (params) => dispatch(fetchEditEventData(params)),
    checkEventGenericHasMachinery: (op_name) => dispatch(checkEventGenericHasMachinery(op_name)),
    checkEventTransportHasMachinery: (params) => dispatch(checkEventTransportHasMachinery(params)),
    fetchEnumerationPesticideFoliarInterception: (params) => dispatch(fetchEnumerationPesticideFoliarInterception(params)),
    fetchEnumerationPesticideApplicationMethods: (params) => dispatch(fetchEnumerationPesticideApplicationMethods(params)),
    checkEventDateIsNotConflicted: (params) => dispatch(checkEventDateIsNotConflicted(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlotEventForm);
