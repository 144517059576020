// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `fieldset{
  border: 2px solid green;
  border-left: 5px solid green;
  border-radius: 10px;
  margin-left: 17px;
  margin-top: 15px;
  width: 100%;
  padding:20px;
}

legend{
  margin-right: 15px;
  padding-left: 5px;
}

.GIS-grid{
  display:flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 10px;
  margin-bottom: 0px;
  padding: 15px 5px 0px 5px;
}

@media (max-width: 600px) {
  .GIS-grid{
    flex-direction: column;
    border-bottom: 2px solid green;
    width: 100%;
    margin-bottom: 0;
    padding: 5px
  }
  .GIS-button-add{
    text-align: center;
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/home/property.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,4BAA4B;EAC5B,mBAAmB;EACnB,iBAAiB;EACjB,gBAAgB;EAChB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,6BAA6B;EAC7B,mBAAmB;EACnB,SAAS;EACT,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE;IACE,sBAAsB;IACtB,8BAA8B;IAC9B,WAAW;IACX,gBAAgB;IAChB;EACF;EACA;IACE,kBAAkB;IAClB,WAAW;EACb;AACF","sourcesContent":["fieldset{\r\n  border: 2px solid green;\r\n  border-left: 5px solid green;\r\n  border-radius: 10px;\r\n  margin-left: 17px;\r\n  margin-top: 15px;\r\n  width: 100%;\r\n  padding:20px;\r\n}\r\n\r\nlegend{\r\n  margin-right: 15px;\r\n  padding-left: 5px;\r\n}\r\n\r\n.GIS-grid{\r\n  display:flex;\r\n  flex-direction: row;\r\n  justify-content: space-around;\r\n  align-items: center;\r\n  gap: 10px;\r\n  margin-bottom: 0px;\r\n  padding: 15px 5px 0px 5px;\r\n}\r\n\r\n@media (max-width: 600px) {\r\n  .GIS-grid{\r\n    flex-direction: column;\r\n    border-bottom: 2px solid green;\r\n    width: 100%;\r\n    margin-bottom: 0;\r\n    padding: 5px\r\n  }\r\n  .GIS-button-add{\r\n    text-align: center;\r\n    width: 100%;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
