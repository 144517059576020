import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { AuthProvider } from "./hooks/useAuth";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Provider } from "react-redux";
import store from "./redux/store";
import { grey } from "@mui/material/colors";
import { ResponseInterceptor } from "./services/ResponseInterceptor";

const root = createRoot(document.getElementById("root"));

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#117d37",
    },
    secondary: {
      main: "#2c3e83",
    },
    tertiary: {
      main: "#009abe",
      contrastText: "#FFFFFF",
    },
  },
  breakpoints: {
    values: {
      xxs: 0,
      xs: 412,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: "1.2rem",
          backgroundColor: "#EFF7EF",
          color: "#038628",
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          fontSize: "1.2rem",
          backgroundColor: grey[100],
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          backgroundColor: grey[100],
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
          opacity: 1,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
        },
      },
    },
  },
});

root.render(
  <BrowserRouter>
    <AuthProvider>

      <ResponseInterceptor />

      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <App />
        </Provider>
      </ThemeProvider>
      
    </AuthProvider>
  </BrowserRouter>
);

reportWebVitals();
