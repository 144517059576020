import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import validationSchema from "./landValidationSchema";
import { Form, Formik } from "formik";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { fetchEnumerationPlotTerrainSoilType } from "../../../redux/enumeratedSlice";
import {
  addPlotTerrains,
  editPlotTerrains,
  fetchUserAll,
} from "../../../redux/userAllSlice";
import PopoverCustom from "../../layout/PopoverCustom";
import { numericField, numericOnly } from "../../../hooks/functions";
import CloseIcon from '@mui/icons-material/CancelRounded';
import Loading from '../../layout/Loading';

export const LandForm = (props) => {
  const {
    openLandForm,
    setOpenLandForm,
    user_id,
    property,
    fetchEnumerationPlotTerrainSoilType,
    soilType,
    addPlotTerrains,
    landSelected,
    mode,
    setMode,
    editPlotTerrains,
  } = props;

  async function init(){
    setTimeout(() => {
      numericField()
    }, 1000);
  }

  useEffect(()=> {
    init()
  }, [])

  useEffect(() => {
    fetchEnumerationPlotTerrainSoilType();
  }, [fetchEnumerationPlotTerrainSoilType]);

  const handleClose = () => {
    setOpenLandForm(false);
    setMode("add");
  };

  const initialValues = {
    user_id: user_id,
    property: property._id,

    name: landSelected.name || "",
    area: landSelected.area || "",
    soil: {
      type: landSelected.soil?.type || "",
      phosphorus: landSelected.soil?.phosphorus || "",
      ph: landSelected.soil?.ph || "",
      clay: landSelected.soil?.clay || "",
      nitrogen: landSelected.soil?.nitrogen || "",
      bulk_density: landSelected.soil?.bulk_density || "",
      soil_loss: landSelected.soil?.soil_loss || "",
      enrichment_factor: landSelected.soil?.enrichment_factor || "",
      erosion_fraction: landSelected.soil?.erosion_fraction || "",
    },
  };

  const handleSubmit = async (values) => {
    const valuesValidated = {
      user_id: user_id,
      property: property._id,
      _id: landSelected._id,
      name: values.name === "" ? null : values.name,
      area: values.area === "" ? null : values.area,
      soil: {
        type: values.soil.type === "" ? null : values.soil.type,
        phosphorus: values.soil.phosphorus === "" ? null : values.soil.phosphorus,
        ph: values.soil.ph === "" ? null : values.soil.ph,
        clay: values.soil.clay === "" ? null : values.soil.clay,
        nitrogen: values.soil.nitrogen === "" ? null : values.soil.nitrogen,
        bulk_density: values.soil.bulk_density === "" ? null : values.soil.bulk_density,
        soil_loss: values.soil.soil_loss === "" ? null : values.soil.soil_loss,
        enrichment_factor: values.soil.enrichment_factor === "" ? null : values.soil.enrichment_factor,
        erosion_fraction: values.soil.erosion_fraction === "" ? null : values.soil.erosion_fraction,
      },
    };

    const params = {
      user_id: valuesValidated.user_id,
      prop_id: valuesValidated.property,
      plot_ter_id: valuesValidated._id,
      payload: {
        name: valuesValidated.name,
        area: valuesValidated.area,
        soil: {
          type: valuesValidated.soil.type,
          bulk_density: valuesValidated.soil.bulk_density,
          clay: valuesValidated.soil.clay,
          enrichment_factor: valuesValidated.soil.enrichment_factor,
          erosion_fraction: valuesValidated.soil.erosion_fraction,
          nitrogen: valuesValidated.soil.nitrogen,
          ph: valuesValidated.soil.ph,
          phosphorus: valuesValidated.soil.phosphorus,
          soil_loss: valuesValidated.soil.soil_loss,
        },
      },
    };
    if (mode === "add") {
      await addPlotTerrains(params);
      handleClose();
    }

    if (mode === "edit") {
      await editPlotTerrains(params);
      setMode("add");
      handleClose();
    }
  };

  return (
    <div>
      {
        soilType.loading ? (
          <Loading />
        ) : (

          <Dialog
            open={openLandForm}
            onClose={handleClose}
            maxWidth="md"
            scroll="body"
          >
            <DialogTitle display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
              <strong>{mode === 'add' ? 'Add Land' : 'Edit Land'}</strong>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{ position: 'absolute', right: 8, top: 8, color: 'green',}}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>

            <Typography variant="body2" sx={{ ml: 3, mt: 1, color: "red", fontWeight: "bold" }}>
              * Required Fields
            </Typography>

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values, { resetForm }) => {
                handleSubmit(values);
                resetForm();
              }}
            >
              {({ values, touched, errors, handleChange, setFieldValue }) => {
                return (
                  <Form noValidate autoComplete="off">
                    <DialogContent sx={{ minWidth: "600px" }}>
                      <Grid container spacing={2}>
                        
                        <Grid item xs={12} sm={6} md={4} lg={4} display={"flex"}>
                          <TextField
                            fullWidth
                            size="small"
                            name="name"
                            label="Name"
                            value={values.name}
                            onChange={handleChange}
                            error={touched.name && Boolean(errors.name)}
                            helperText={touched.name && errors.name}
                            required
                          />
                        </Grid>
                        
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                          <FormControl fullWidth variant="outlined" required>
                            <InputLabel
                              size="small"
                              htmlFor="area"
                              error={touched.area && Boolean(errors.area)}
                            >
                              Plot Area
                            </InputLabel>
                            <OutlinedInput
                              size="small"
                              name="area"
                              id="area"
                              label="Plot Area"
                              value={values.area.toString() || ""}
                              onChange={handleChange}
                              onKeyDown={numericOnly}
                              error={touched.area && Boolean(errors.area)}
                              inputProps={{ step: 0.1, min: 0 }}
                              type="number"
                              endAdornment={
                                <InputAdornment position="end">
                                  <Box mr={1}>[ha]</Box>
                                  <PopoverCustom
                                    info={"Total area of the farm"}
                                  />
                                </InputAdornment>
                              }
                            />
                            {
                              touched.area && errors.area && (
                                <FormHelperText error>{errors.area}</FormHelperText>
                              )
                            }
                          </FormControl>
                        </Grid>

                        <Grid item xs={12}>Soil</Grid>

                        <Grid item xs={12} sm={6} md={4} lg={4}>
                          <TextField
                            required
                            fullWidth
                            size="small"
                            name="soil.type"
                            select
                            label="Type"
                            value={values.soil.type || ''}
                            onChange={handleChange}
                            error={touched.soil?.type && Boolean(errors.soil?.type)}
                            helperText={touched.soil?.type && errors.soil?.type}
                          >
                            {
                              soilType.registros.map((option) => (
                                <MenuItem key={option} value={option}>
                                  {option}
                                </MenuItem>
                              ))
                            }
                          </TextField>
                        </Grid>

                        

                        <Grid item xs={12} sm={6} md={4} lg={4}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel
                              size="small"
                              htmlFor="soil.phosphorus"
                              error={Boolean(errors.soil?.phosphorus)}
                            >
                              P Concentration
                            </InputLabel>
                            <OutlinedInput
                              size="small"
                              name="soil.phosphorus"
                              id="soil.phosphorus"
                              label="P Concentration"
                              value={values.soil.phosphorus.toString() || ""}
                              onChange={handleChange}
                              onKeyDown={numericOnly}
                              inputProps={{ step: 0.1, min: 0, max: 1}}
                              error={Boolean(errors.soil?.phosphorus)}
                              type="number"
                              endAdornment={
                                <InputAdornment position="end">
                                  <Box mr={1}>[kg P / kg soil]</Box>
                                  <PopoverCustom info={"Concentration of phosphorus in the soil"}/>
                                </InputAdornment>
                              }
                            />
                            {
                                errors.soil?.phosphorus && (
                                <FormHelperText error>
                                  {errors.soil?.phosphorus}
                                </FormHelperText>
                              )
                            }
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={4}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel
                              size="small"
                              htmlFor="soil.ph"
                              error={Boolean(errors.soil?.ph)}
                            >
                              pH
                            </InputLabel>
                            <OutlinedInput
                              size="small"
                              name="soil.ph"
                              id="soil.ph"
                              label="pH"
                              value={values.soil.ph.toString() || ""}
                              onChange={handleChange}
                              onKeyDown={numericOnly}
                              inputProps={{ step: 0.1, min: 0, max: 14 }}
                              error={Boolean(errors.soil?.ph)}
                              type="number"
                              endAdornment={
                                <InputAdornment position="end">
                                  <Box>[-]</Box>
                                </InputAdornment>
                              }
                            />
                            {
                              errors.soil?.ph && (
                                <FormHelperText error>
                                  {errors.soil?.ph}
                                </FormHelperText>
                              )
                            }
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={4}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel
                              size="small"
                              htmlFor="soil.clay"
                              error={Boolean(errors.soil?.clay)}
                            >
                              Clay Content
                            </InputLabel>
                            <OutlinedInput
                              size="small"
                              name="soil.clay"
                              id="soil.clay"
                              label="Clay Content"
                              value={values.soil.clay.toString() || ""}
                              onChange={handleChange}
                              type="number"
                              inputProps={{ step: 0.1, min: 0, max: 100,}}
                              error={Boolean(errors.soil?.clay)}
                              endAdornment={
                                <InputAdornment position="end">
                                  <Box mr={1}>[%]</Box>
                                  <PopoverCustom
                                    info={"Clay content of the soil. Use numbers from 0 to 100"}
                                  />
                                </InputAdornment>
                              }
                              
                            />
                            {
                              errors.soil?.clay && (
                                <FormHelperText error>
                                  {errors.soil?.clay}
                                </FormHelperText>
                              )
                            }
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={4}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel
                              size="small"
                              htmlFor="soil.nitrogen"
                              error={Boolean(errors.soil?.nitrogen)}
                            >
                              N Concentration
                            </InputLabel>
                            <OutlinedInput
                              size="small"
                              name="soil.nitrogen"
                              id="soil.nitrogen"
                              label="N Concentration"
                              value={values.soil.nitrogen.toString() || ""}
                              onChange={handleChange}
                              onKeyDown={numericOnly}
                              inputProps={{step: 0.01, min: 0, max: 1,}}
                              error={Boolean(errors.soil?.nitrogen)}
                              type="number"
                              endAdornment={
                                <InputAdornment position="end">
                                  <Box mr={1}>[kg N / kg soil]</Box>
                                  <PopoverCustom info={"Concentration of nitrogen in the soil"}/>
                                </InputAdornment>
                              }
                            />
                            {
                              errors.soil?.nitrogen && (
                                <FormHelperText error>
                                  {errors.soil?.nitrogen}
                                </FormHelperText>
                              )
                            }
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={4}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel
                              size="small"
                              htmlFor="soil.bulk_density"
                              error={Boolean(errors.soil?.bulk_density)}
                            >
                              Bulk Density
                            </InputLabel>
                            <OutlinedInput
                              size="small"
                              name="soil.bulk_density"
                              id="soil.bulk_density"
                              label="Bulk Density"
                              value={values.soil.bulk_density.toString() || ""}
                              onChange={handleChange}
                              error={Boolean(errors.soil?.bulk_density)}
                              type="number"
                              endAdornment={
                                <InputAdornment position="end">
                                  <Box mr={1}>[kg / m³]</Box>
                                  <PopoverCustom info={"Bulk density of the soil"}/>
                                </InputAdornment>
                              }
                            />
                            {
                              errors.soil?.bulk_density && (
                                <FormHelperText error>
                                  {errors.soil?.bulk_density}
                                </FormHelperText>
                              )
                            }
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={4}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel
                              size="small"
                              htmlFor="soil.soil_loss"
                              error={Boolean(errors.soil?.soil_loss)}
                            >
                              Soil Loss
                            </InputLabel>
                            <OutlinedInput
                              size="small"
                              name="soil.soil_loss"
                              id="soil.soil_loss"
                              label="Soil Loss"
                              value={values.soil.soil_loss.toString() || ""}
                              onChange={handleChange}
                              onKeyDown={numericOnly}
                              inputProps={{ step: 0.01, min: 0 }}
                              error={Boolean(errors.soil?.soil_loss)}
                              type="number"
                              endAdornment={
                                <InputAdornment position="end">
                                  <Box mr={1}>[kg soil / (ha * year)]</Box>
                                  <PopoverCustom info={"Soil loss due to erosion. Calculated by the Universal Soil Loss Equation (USLE)"}/>
                                </InputAdornment>
                              }
                            />

                            {
                              errors.soil?.soil_loss && (
                                <FormHelperText error>
                                  {errors.soil?.soil_loss}
                                </FormHelperText>
                              )
                            }
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={4}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel
                              size="small"
                              htmlFor="soil.enrichment_factor"
                              error={Boolean(errors.soil?.enrichment_factor)}
                            >
                              P Enrichment Factor
                            </InputLabel>
                            <OutlinedInput
                              size="small"
                              name="soil.enrichment_factor"
                              id="soil.enrichment_factor"
                              label="P Enrichment Factor"
                              value={values.soil.enrichment_factor.toString() || ""}
                              onChange={handleChange}
                              onKeyDown={numericOnly}
                              inputProps={{step: 0.01, min: 0, max: 1,}}
                              error={Boolean(errors.soil?.enrichment_factor)}
                              type="number"
                              endAdornment={
                                <InputAdornment position="end">
                                  <Box mr={1}>[-]</Box>
                                  <PopoverCustom
                                    info={"Phosphorus enrichment factor of the soil. See WFLDB et al. 2019, section 3.9.8.3"}
                                  />
                                </InputAdornment>
                              }
                            />
                            {
                              errors.soil?.enrichment_factor && (
                                <FormHelperText error>
                                  {errors.soil?.enrichment_factor}
                                </FormHelperText>
                              )
                            }
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={4}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel
                              size="small"
                              htmlFor="soil.erosion_fraction"
                              error={Boolean(errors.soil?.erosion_fraction)}
                            >
                              P Erosion Fraction
                            </InputLabel>
                            <OutlinedInput
                              size="small"
                              name="soil.erosion_fraction"
                              id="soil.erosion_fraction"
                              label="P Erosion Fraction"
                              value={values?.soil?.erosion_fraction.toString() || ""}
                              onChange={handleChange}
                              onKeyDown={numericOnly}
                              inputProps={{ step: 0.00001, min: 0, max: 1 }}
                              error={Boolean(errors.soil?.erosion_fraction)}
                              type="number"
                              endAdornment={
                                <InputAdornment position="end">
                                  <Box mr={1}>[-]</Box>
                                  <PopoverCustom
                                    info={"Fraction of eroded soil that reaches the river. See WFLDB et al. 2019, section 3.9.8.3"}
                                  />
                                </InputAdornment>
                              }
                            />
                            {
                              errors.soil?.erosion_fraction && (
                                <FormHelperText error>
                                  {errors.soil?.erosion_fraction}
                                </FormHelperText>
                              )
                            }
                          </FormControl>
                        </Grid>

                      </Grid>
                    </DialogContent>

                    <DialogActions>
                      <Box sx={{ display: "flex", gap: 1 }}>
                        <Button
                          size="small"
                          onClick={handleClose}
                          variant="outlined"
                          startIcon={<CancelRoundedIcon />}
                        >
                          Cancel
                        </Button>
                        <Button
                          size="small"
                          variant="contained"
                          startIcon={<SaveRoundedIcon />}
                          autoFocus
                          type="submit"
                        >
                          save
                        </Button>
                      </Box>
                    </DialogActions>
                  </Form>
                );
              }}
            </Formik>
          </Dialog>
        )
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userAll: {
      loading: state.userAll.loading,
      registro: state.userAll.registro,
      error: state.userAll.error,
    },
    soilType: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationPlotTerrainSoilType,
      error: state.enumerated.error,
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserAll: (user_id) => dispatch(fetchUserAll(user_id)),
    fetchEnumerationPlotTerrainSoilType: () => dispatch(fetchEnumerationPlotTerrainSoilType()),
    addPlotTerrains: (params) => dispatch(addPlotTerrains(params)),
    editPlotTerrains: (params) => dispatch(editPlotTerrains(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LandForm);
