import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { FieldArray, Form, Formik, getIn } from "formik";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import validationSchema from "./productsValidationSchema";
import {
  fetchCheckPesticideFromTableHasDensity,
  fetchEnumerationFertiliserName,
  fetchEnumerationFertiliserNpk,
  fetchEnumerationFertiliserSourcesK,
  fetchEnumerationFertiliserSourcesN,
  fetchEnumerationFertiliserSourcesP,
  fetchEnumerationPesticideActivePrincipleUnits,
  fetchEnumerationPesticideType,
  fetchEnumerationProductCorretiveName,
  fetchEnumerationProductFertiliserLiquidManureNames,
  fetchEnumerationProductFertiliserSolidManureNames,
  fetchEnumerationProductFertiliserType,
  fetchEnumerationProductPesticideActivePrinciplesNames,
  fetchEnumerationProductPesticideFromTableNames,
  fetchEnumerationWaterNames,
} from "../../redux/enumeratedSlice";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import PopoverCustom from "../layout/PopoverCustom";
import { editProduct } from "../../redux/userAllSlice";
import { numericField, numericOnly } from "../../hooks/functions";
import CloseIcon from "@mui/icons-material/CancelRounded";
import Loading from '../layout/Loading';

export const ProductsFormEdit = (props) => {
  const {
    openProductFormEdit,
    setOpenProductFormEdit,
    productSelected,
    fetchEnumerationPesticideType,
    fertiliserType,
    fetchEnumerationProductFertiliserType,
    fetchEnumerationProductFertiliserSolidManureNames,
    fetchEnumerationProductFertiliserLiquidManureNames,
    fetchEnumerationProductPesticideFromTableNames,
    fetchEnumerationProductPesticideActivePrinciplesNames,
    pesticideActivePrinciplesNames,
    fetchEnumerationProductCorretiveName,
    fetchEnumerationFertiliserName,
    fetchEnumerationFertiliserNpk,
    fertiliserNpk,
    editProduct,
    fetchCheckPesticideFromTableHasDensity,
    fetchEnumerationFertiliserSourcesN,
    fetchEnumerationFertiliserSourcesP,
    fetchEnumerationFertiliserSourcesK,
    fertiliserSourcesN,
    fertiliserSourcesP,
    fertiliserSourcesK,
    fetchEnumerationPesticideActivePrincipleUnits,
    pesticideActivePrincipleUnits,
    property,
    fetchEnumerationWaterNames,
    waterNames
  } = props;

  const [typeInputName, setTypeInputName] = useState("input");
  const [optionsNames, setOptionsNames] = useState([]);
  const [density, setDensity] = useState("");
  const [hasDensity, setHasDensity] = useState(false);
  const [hasDensityRequired, setHasDensityRequired] = useState(true);
  const [isLoading, setIsLoading] = useState(true)
  const [initialValues, setInitialValues] = useState({})
  const [loading, setLoading] = useState(true)
  const [npkRequired, setNpkRequired] = useState(false)


  const handleClose = () => {
    setOpenProductFormEdit(false);
  };

  async function init(){
    setTimeout(() => {
      numericField()
    }, 1000);
  }

  useEffect(()=> {
    init()
  }, [initialValues])

  useEffect(() => {
    fetchEnumerationWaterNames();
  }, [fetchEnumerationWaterNames]);
  
  useEffect(() => {
    fetchEnumerationPesticideActivePrincipleUnits();
  }, [fetchEnumerationPesticideActivePrincipleUnits]);

  useEffect(() => {
    fetchEnumerationPesticideType();
  }, [fetchEnumerationPesticideType]);

  useEffect(() => {
    fetchEnumerationProductFertiliserType();
  }, [fetchEnumerationProductFertiliserType]);

  useEffect(() => {
    fetchEnumerationProductPesticideActivePrinciplesNames();
  }, [fetchEnumerationProductPesticideActivePrinciplesNames]);


  useEffect(() => {
    fetchEnumerationFertiliserSourcesN();
    fetchEnumerationFertiliserSourcesP();
    fetchEnumerationFertiliserSourcesK();
  }, [
    fetchEnumerationFertiliserSourcesK,
    fetchEnumerationFertiliserSourcesN,
    fetchEnumerationFertiliserSourcesP,
  ]);

  useEffect(()=> {
    setInitialValues(
      {
        _id: productSelected._id || "",
        main_type: productSelected.main_type || "",
        name: productSelected.name || "",
        dens: productSelected.dens || "",
        type: productSelected.type || "",
        P: productSelected.P || "",
        N: productSelected.N || "",
        K: productSelected.K || "",
        CaCO3: productSelected.CaCO3 || "",
        heavy_metal_content: productSelected.heavy_metal_content || {},
        source: productSelected.source || {},
        active_principles: productSelected.active_principles || [],
      }
    )
  },[productSelected])

  useEffect(() => {

    if (productSelected.dens) {
      setHasDensity(true);
      setDensity(productSelected.dens);
    }

    setHasDensityRequired(true);
    if (productSelected.main_type === "fertiliser") {
      if ( productSelected.type === "mineral" || productSelected.type === "mineral, generic" ) {
        setHasDensityRequired(false);
      }
    }

    setHasDensity(false);
    const handleHasDensity = async () => {
      if (productSelected.main_type === "fertiliser") {
        if (
          productSelected.type === "sugarcane vinasse" ||
          productSelected.type === "liquid manure" ||
          productSelected.type === "mineral" ||
          productSelected.type === "mineral, generic"
        ) {
          setHasDensity(true);
        }

        if (
          productSelected.type === "liquid manure" ||
          productSelected.type === "solid manure" ||
          productSelected.type === "sugarcane ashe" ||
          productSelected.type === "sugarcane filter cake"||
          productSelected.type === "sugarcane vinasse"
        ) {
          setNpkRequired(true);
        }

      }
      
      if (productSelected.main_type === "pesticide") {
        if (productSelected.type === "from table") {
          const result = await fetchCheckPesticideFromTableHasDensity(
            productSelected.name
          );
          if (result.payload === true) {
            setHasDensity(true);
          } else {
            setHasDensity(false);
          }
        }
        if (productSelected.type === "generic") {
          const unit = productSelected.active_principles.filter((item) => item.unit === "g/L");
          unit.length > 0 ? setHasDensity(true) : setHasDensity(false);
        }
      }
    };

    handleHasDensity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productSelected]);

  useEffect(() => {
    const getNamesEnum = async () => {
      setTypeInputName("input");
      
      if (productSelected.type === "solid manure") {

        setTypeInputName("enum");
        const result = await fetchEnumerationProductFertiliserSolidManureNames()
        setOptionsNames(result.payload);

      } else if (productSelected.type === "liquid manure") {

        setTypeInputName("enum");
        const result = await fetchEnumerationProductFertiliserLiquidManureNames()
        setOptionsNames(result.payload);

      } else if (productSelected.type === "mineral") {

        setTypeInputName("enum");
        const result = await fetchEnumerationFertiliserName()
        setOptionsNames(result.payload);

      } else if (productSelected.main_type === "corrective") {

        setTypeInputName("enum");
        const result = await fetchEnumerationProductCorretiveName()
        setOptionsNames(result.payload);

      } else if (productSelected.main_type === "pesticide") {
        if (productSelected.type === "from table") {

          setTypeInputName("enum");
          const result = await fetchEnumerationProductPesticideFromTableNames()
          setOptionsNames(result.payload);

        }
      }
      setIsLoading(false) 
    }

    getNamesEnum()
     
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productSelected, initialValues]);

  const handleChangeName = (event, values, setFieldValue) => {
    setFieldValue("name", event.target.value);
    if (values.main_type === "fertiliser") {
      fetchEnumerationFertiliserNpk(event.target.value);
    }
  };

  const handleChangeNameInput = (event, values, setFieldValue) => {
    setFieldValue("name", event.target.value);
  };

  const handleChangeActivePrinciplesUnit = async (values, value, setFieldValue, index) => {
    await setFieldValue(`active_principles[${index}].unit`, value);
    const apAjustado = [...values.active_principles];
    if (values.active_principles[index].unit === "g/L" && value !== "g/L") {
      apAjustado.splice(index, 1);
    }
    hasDensityPesticideGeneric(values, value, apAjustado, setFieldValue);
  };

  function hasDensityPesticideGeneric( values, vlrAtual, apAjustado, setFieldValue) {
    setHasDensity(false);
    if (vlrAtual === "g/L") {
      setHasDensity(true);
    } else {
      const units = apAjustado.filter((item) => item.unit === "g/L");
      if (units.length > 0) {
        setHasDensity(true);
      } else {
        setHasDensity(false);
        setDensity("");
        setFieldValue(`dens`, "");
      }
    }
  }

  const handleChangeDensity = (e, setFieldValue) => {
    setFieldValue("dens", e.target.value);
    setDensity(e.target.value);
  };

  const handleSubmit = async (values, setFieldError) => {
    
    if (density === "" && hasDensity && hasDensityRequired) {
      setFieldError("dens", "Required");
      return;
    }

    const activePrinciplesValidated = await values.active_principles.filter((el) => el.name !== "");

    let npkValidated = {};
    if (values.N !== "" || values.P !== "" || values.K !== "") {
      npkValidated = {
        N: values.N === "" ? null : values.N,
        P: values.P === "" ? null : values.P,
        K: values.K === "" ? null : values.K,
      };
    }
    Object.keys(npkValidated).forEach(
      (key) => npkValidated[key] == null && delete npkValidated[key]
    );

    let heavyMetalContentValidated = {};
    if (values.heavy_metal_content) {
      heavyMetalContentValidated = {
        Cd: values.heavy_metal_content.Cd === "" ? null : values.heavy_metal_content.Cd,
        Cu: values.heavy_metal_content.Cu === "" ? null : values.heavy_metal_content.Cu,
        Zn: values.heavy_metal_content.Zn === "" ? null : values.heavy_metal_content.Zn,
        Pb: values.heavy_metal_content.Pb === "" ? null : values.heavy_metal_content.Pb,
        Ni: values.heavy_metal_content.Ni === "" ? null : values.heavy_metal_content.Ni,
        Cr: values.heavy_metal_content.Cr === "" ? null : values.heavy_metal_content.Cr,
        Hg: values.heavy_metal_content.Hg === "" ? null : values.heavy_metal_content.Hg,
        As: values.heavy_metal_content.As === "" ? null : values.heavy_metal_content.As,
      };

      Object.keys(heavyMetalContentValidated).forEach((key) =>
          heavyMetalContentValidated[key] == null && delete heavyMetalContentValidated[key]
      );
    }

    let sourceValidated = values.source;
    Object.keys(sourceValidated).forEach((key) => 
      sourceValidated[key] === "" && delete sourceValidated[key]
    );

    let params = {};
    if (values.main_type === "corrective") {
      params = {
        prod_id: productSelected._id,
        prop_id: property._id,
        payload: {
          main_type: values.main_type,
          dens: values.dens === "" ? null : values.dens,
          name: values.name,
          CaCO3: values.CaCO3 || null,
          heavy_metal_content: heavyMetalContentValidated,
        },
      };
    } else if (values.main_type === "fertiliser" && values.type === "mineral, generic") {
      params = {
        prod_id: productSelected._id,
        prop_id: property._id,
        payload: {
          main_type: values.main_type,
          name: values.name,
          dens: values.dens === "" ? null : values.dens,
          type: values.type,
          ...npkValidated,
          heavy_metal_content: heavyMetalContentValidated,
          source: sourceValidated,
        },
      };
    } else if (values.main_type === "fertiliser" && (values.type === "sugarcane filter cake" || values.type === "mineral")) {
      values.active_principles = [];
      params = {
        prod_id: productSelected._id,
        prop_id: property._id,
        payload: {
          main_type: values.main_type,
          name: values.name,
          dens: values.dens === "" ? null : values.dens,
          type: values.type,
          ...npkValidated,
          heavy_metal_content: heavyMetalContentValidated,
        },
      };
    } else if (values.main_type === "fertiliser" && (values.type === "solid manure" || values.type === "liquid manure")) {
      params = {
        prod_id: productSelected._id,
        prop_id: property._id,
        payload: {
          main_type: values.main_type,
          name: values.name,
          dens: values.dens === "" ? null : values.dens,
          type: values.type,
          ...npkValidated,
          heavy_metal_content: heavyMetalContentValidated,
        },
      };
    } else if (values.main_type === "fertiliser" && (values.type === "sugarcane ashe" || values.type === "sugarcane vinasse")) {
      params = {
        prod_id: productSelected._id,
        prop_id: property._id,
        payload: {
          main_type: values.main_type,
          name: values.name,
          dens: values.dens === "" ? null : values.dens,
          type: values.type,
          ...npkValidated,
          heavy_metal_content: heavyMetalContentValidated,
        },
      };
    } else if (values.main_type === "pesticide" && values.type === "generic") {
      params = {
        prod_id: productSelected._id,
        prop_id: property._id,
        payload: {
          main_type: values.main_type,
          type: values.type,
          dens: values.dens === "" ? null : values.dens,
          name: values.name,
          active_principles: activePrinciplesValidated,
        },
      };
    } else if (values.main_type === "pesticide" && values.type === "from table") {
      params = {
        prod_id: productSelected._id,
        prop_id: property._id,
        payload: {
          main_type: values.main_type,
          type: values.type,
          dens: values.dens === "" ? null : values.dens,
          name: values.name,
        },
      };
    } else if (values.main_type === "water") {
      params = {
        prod_id: productSelected._id,
        prop_id: property._id,
        payload: {
          main_type: values.main_type,
          name: values.name,
          type: values.type,
        },
      };
    }
    await editProduct(params)

    handleClose();
    setDensity("");
  };

  const handleChangeType = (e, values, setFieldValue) => {
    setFieldValue('type', e.target.value)

    if(values.main_type === 'water'){
      setFieldValue('name', e.target.value)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      const dialog = document.querySelector('.dialog')
      if(dialog){
        dialog.scrollIntoView({behavior: 'smooth', block: 'start'})
      }
      setLoading(false)
      }, 1000);
  }, [])

  const handleChangeNConcentration = (event, setFieldValue, setFieldError) => {
    const concentration = event.target.value;
    setFieldValue('N', concentration)
    if(concentration === "" ){
      setFieldValue('source.N', "")
      setFieldError('source.N', "")
    }
  }

  const handleChangePConcentration = (event, setFieldValue) => {
    const concentration = event.target.value;
    setFieldValue('P', concentration)
    if(concentration === "" ){
      setFieldValue('source.P', "")
    }
  }

  const handleChangeKConcentration = (event, setFieldValue) => {
    const concentration = event.target.value;
    setFieldValue('K', concentration)
    if(concentration === ""){
      setFieldValue('source.K', "")
    }
  }

  return (
    <div>
      <Dialog
        open={openProductFormEdit}
        onClose={handleClose}
        maxWidth="md"
        scroll="body"
        className='dialog'
      >
        <DialogTitle
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{ minWidth: { sm: "400px", md: "600px", lg: "600px" }, }}
        >
          <strong>Edit Agricultural Inputs</strong>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: "absolute", right: 8, top: 8, color: "green",}}
          >
            <CloseIcon />
          </IconButton>

        </DialogTitle>

        <Typography variant="body2" sx={{ ml: 3, mt: 1, mb:1, color: "red", fontWeight: "bold" }}>
          * Required Fields
        </Typography>

        {
          isLoading 
          ? (
            <Backdrop open={true} sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}>
              <CircularProgress color="secondary" />
            </Backdrop>
          ) 
          : (
            <Formik
              initialValues={initialValues}
              enableReinitialize
              validationSchema={validationSchema}
              onSubmit={(values, { setFieldError }) => handleSubmit(values, setFieldError)}
            >
              {({ values, touched, errors, handleChange, setFieldValue, setFieldError }) => {
                return (
                  <Form noValidate autoComplete="off">
                    
                    {
                      loading ? (
                        <Loading />
                      ) : (
                        <DialogContent sx= {{ minHeight: "200px" }} >
                          
                          <Grid container spacing={2}>

                            <Grid item xs={12} sm={6} md={4} lg={4}>
                              <TextField
                                disabled
                                required
                                fullWidth
                                size="small"
                                name="main_type"
                                label="Type"
                                value={values.main_type}
                              />
                            </Grid>

                            {
                              values.main_type === "pesticide" 
                              ? (
                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                  <TextField
                                    disabled
                                    required
                                    fullWidth
                                    size="small"
                                    label="Specific Type"
                                    value={values.type}
                                  />
                                </Grid>
                              ) 
                              : null
                            }

                            {
                              values.main_type === "fertiliser" 
                              ? (
                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                  <TextField
                                    disabled
                                    required
                                    fullWidth
                                    size="small"
                                    select
                                    label="Specific Type"
                                    value={values.type}
                                    onChange={handleChange}
                                  >
                                    {
                                      fertiliserType.registros.map((option) => (
                                        <MenuItem key={option} value={option}>
                                          {option}
                                        </MenuItem>
                                      ))
                                    }
                                  </TextField>
                                </Grid>
                              ) 
                              : null
                            }

                            {
                              values.main_type === "water" 
                              ? (
                                  <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <TextField
                                      required
                                      fullWidth
                                      size="small"
                                      name="type"
                                      select
                                      label="Specific Type"
                                      value={values.type || ""}
                                      defaultValue={""}
                                      onChange={(e) => handleChangeType(e, values, setFieldValue)}
                                      error={touched.type && Boolean(errors.type)}
                                      helperText={touched.type && errors.type}
                                    >
                                      {
                                        waterNames.registros 
                                        ? waterNames.registros.map((option) => (
                                          <MenuItem key={option} value={option}>
                                            {option}
                                          </MenuItem>
                                        ))
                                        : (
                                          <MenuItem key={values.type} value={values.type }>
                                            {values.type}
                                          </MenuItem>
                                        )
                                      }
                                    </TextField>
                                  </Grid>
                                ) 
                              : null
                            }

                            {
                              typeInputName === "input" 
                              ? (
                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                  <TextField
                                    fullWidth
                                    size="small"
                                    name="name"
                                    label="Name"
                                    value={values.name}
                                    onChange={(event) => { handleChangeNameInput(event, values, setFieldValue);}}
                                    disabled={
                                      values.type === "sugarcane ashe" ||
                                      values.type === "sugarcane filter cake" ||
                                      values.type === "sugarcane vinasse" ||
                                      values.main_type === 'water'
                                    }
                                  />
                                </Grid>
                              ) 
                              : null
                            }
                            
                            {
                              typeInputName === "enum" 
                              ? (
                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                  <TextField
                                    fullWidth
                                    size="small"
                                    select
                                    label="Name"
                                    value={values.name || null}
                                    onChange={(event) => handleChangeName(event, values, setFieldValue)}
                                  >
                                    {
                                      optionsNames.length === 0 && (
                                        <MenuItem key={'no_option'} value={''}>
                                          {'No options'}
                                        </MenuItem>
                                      )
                                    }

                                    {
                                      optionsNames.map((option) => (
                                        <MenuItem key={option} value={option}>
                                          {option}
                                        </MenuItem>
                                      ))
                                    }
                                  </TextField>
                                </Grid>
                              ) 
                              : null
                            }

                            {
                              values.main_type === 'corrective'
                              && (
                                values.name === 'Limestone, Calcitic'
                                || values.name === 'Limestone, Dolomitic'
                                || values.name === 'Limestone, Magnesian'
                                || values.name === 'Limestone, Unspecified'
                              ) 
                              ? (
                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                  <FormControl fullWidth variant="outlined">
                                    <InputLabel size="small" htmlFor="N" error={touched.CaCO3 && Boolean(errors.CaCO3)}>CaCO3 content</InputLabel>
                                    <OutlinedInput
                                      fullWidth
                                      size="small"
                                      name="CaCO3"
                                      id="CaCO3"
                                      label="CaCO3 content"
                                      value={values.CaCO3}
                                      onChange={handleChange}
                                      inputProps={{ step: 0.01, min: 0, max: 1}}
                                      onKeyDown={numericOnly}
                                      type="number"
                                      endAdornment={
                                        <InputAdornment position="end">
                                          <Box mr={1}>[-]</Box>
                                        </InputAdornment>
                                      }
                                      error={touched.CaCO3 && Boolean(errors.CaCO3)}
                                    />
                                    {
                                      touched.CaCO3 && errors.CaCO3 && (
                                        <FormHelperText error>
                                          {errors.CaCO3}
                                        </FormHelperText>
                                      )
                                    }
                                  </FormControl>
                                </Grid>
                              )
                              : null
                            }

                            {
                              hasDensity 
                              ? (
                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                  <FormControl fullWidth variant="outlined">
                                    <InputLabel size="small" htmlFor="dens" error={touched.dens && Boolean(errors.dens)} required={hasDensityRequired}>
                                      Density
                                    </InputLabel>
                                    <OutlinedInput
                                      size="small"
                                      name="dens"
                                      id="dens"
                                      label="Density"
                                      value={values.dens}
                                      onChange={(e) => { handleChangeDensity(e, setFieldValue); }}
                                      onKeyDown={numericOnly}
                                      error={touched.dens && Boolean(errors.dens)}
                                      inputProps={{ step: 0.01 }}
                                      type="number"
                                      endAdornment={
                                        <InputAdornment position="end">
                                          <Box>[kg / l]</Box>
                                        </InputAdornment>
                                      }
                                    />
                                    {touched.dens && errors.dens && (
                                      <FormHelperText error>{errors.dens}</FormHelperText>
                                    )}
                                  </FormControl>
                                </Grid>
                              ) 
                              : null
                            }

                            {
                              values.type === "mineral, generic" || fertiliserNpk.registros.findIndex((val) => val === "N") !== -1 
                              ? (
                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                  <FormControl fullWidth variant="outlined">
                                    <InputLabel 
                                      size="small" 
                                      htmlFor="N"
                                      error={touched.N && Boolean(errors.N)}
                                      required={npkRequired && !Boolean(values.P) && !Boolean(values.K)}
                                    >
                                      N concentration
                                    </InputLabel>
                                    <OutlinedInput
                                      fullWidth
                                      size="small"
                                      name="N"
                                      id="N"
                                      label="N concentration"
                                      value={values.N}
                                      //onChange={handleChange}
                                      onChange={(event) => handleChangeNConcentration(event, setFieldValue, setFieldError) }
                                      error={Boolean(errors.N)}
                                      inputProps={{ step: 0.01 }}
                                      onKeyDown={numericOnly}
                                      type="number"
                                      endAdornment={
                                        <InputAdornment position="end">
                                          <Box mr={1}>[kg N / kg]</Box>
                                          <PopoverCustom info={"Nitrogen concentration"} />
                                        </InputAdornment>
                                      }
                                    />
                                    {errors.N && (<FormHelperText error>{errors.N}</FormHelperText>)}
                                  </FormControl>
                                </Grid>
                              ) 
                              : null
                            }

                            {
                              values.type === "mineral, generic" || fertiliserNpk.registros.findIndex((val) => val === "P") !== -1 
                              ? (
                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                  <FormControl fullWidth variant="outlined">
                                    <InputLabel 
                                      size="small" 
                                      htmlFor="P"
                                      error={touched.P && Boolean(errors.P)}
                                      required={npkRequired && !Boolean(values.N) && !Boolean(values.K)}
                                    >
                                      P concentration
                                    </InputLabel>
                                    <OutlinedInput
                                      fullWidth
                                      size="small"
                                      name="P"
                                      id="P"
                                      label="P concentration"
                                      value={values.P}
                                      onChange={(event) => handleChangePConcentration(event, setFieldValue) }
                                      error={Boolean(errors.P)}
                                      inputProps={{ step: 0.01 }}
                                      onKeyDown={numericOnly}
                                      type="number"
                                      endAdornment={
                                        <InputAdornment position="end">
                                          <Box mr={1}>[kg P / kg]</Box>
                                          <PopoverCustom
                                            info={"Phosphorus concentration"}
                                          />
                                        </InputAdornment>
                                      }
                                    />
                                    {errors.P && (<FormHelperText error>{errors.P}</FormHelperText>)}
                                  </FormControl>
                                </Grid>
                              ) 
                              : null
                            }

                            {
                              values.type === "mineral, generic" || fertiliserNpk.registros.findIndex((val) => val === "K") !== -1 
                              ? (
                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                  <FormControl fullWidth variant="outlined">
                                    <InputLabel 
                                      size="small" 
                                      htmlFor="K"
                                      required={npkRequired && !Boolean(values.N) && !Boolean(values.P)}
                                    >
                                      K concentration
                                    </InputLabel>
                                    <OutlinedInput
                                      fullWidth
                                      size="small"
                                      name="K"
                                      id="K"
                                      label="K concentration"
                                      value={values.K}
                                      onChange={(event) => handleChangeKConcentration(event, setFieldValue) }
                                      error={touched.K && Boolean(errors.K)}
                                      inputProps={{ step: 0.01 }}
                                      onKeyDown={numericOnly}
                                      type="number"
                                      endAdornment={
                                        <InputAdornment position="end">
                                          <Box mr={1}>[kg K / kg]</Box>
                                          <PopoverCustom
                                            info={"Potassium concentration"}
                                          />
                                        </InputAdornment>
                                      }
                                    />
                                    {touched.K && errors.K && (
                                      <FormHelperText error>{errors.K}</FormHelperText>
                                    )}
                                  </FormControl>
                                </Grid>
                              ) 
                              : null
                            }

                            {
                              values.main_type === "fertiliser" || values.main_type === "corrective" 
                              ? (
                                <>
                                  <Grid item xs={12} sm={12} md={12} lg={12}>
                                    Heavy Metal Content
                                  </Grid>

                                  <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <FormControl fullWidth variant="outlined">
                                      <InputLabel size="small" htmlFor="heavy_metal_content.Cd">
                                        Cd content
                                      </InputLabel>
                                      <OutlinedInput
                                        fullWidth
                                        size="small"
                                        name="heavy_metal_content.Cd"
                                        id="heavy_metal_content.Cd"
                                        label="Cd content"
                                        value={values.heavy_metal_content?.Cd || ""}
                                        onChange={handleChange}
                                        onKeyDown={numericOnly}
                                        error={touched.heavy_metal_content?.Cd && Boolean(errors.heavy_metal_content?.Cd)}
                                        inputProps={{ step: 0.01 }}
                                        type="number"
                                        endAdornment={
                                          <InputAdornment position="end">
                                            <Box mr={1}>[kg Cd / kg]</Box>
                                            <PopoverCustom info={"Cadmium content"} />
                                          </InputAdornment>
                                        }
                                      />
                                      {
                                        touched.heavy_metal_content?.Cd && errors.heavy_metal_content?.Cd && (
                                          <FormHelperText error>
                                            {errors.heavy_metal_content?.Cd}
                                          </FormHelperText>
                                        )
                                      }
                                    </FormControl>
                                  </Grid>

                                  <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <FormControl fullWidth variant="outlined">
                                      <InputLabel size="small" htmlFor="heavy_metal_content.Cu">
                                        Cu content
                                      </InputLabel>
                                      <OutlinedInput
                                        fullWidth
                                        size="small"
                                        name="heavy_metal_content.Cu"
                                        id="heavy_metal_content.Cu"
                                        label="Cu content"
                                        value={values.heavy_metal_content?.Cu || ""}
                                        onChange={handleChange}
                                        onKeyDown={numericOnly}
                                        error={touched.heavy_metal_content?.Cu && Boolean(errors.heavy_metal_content?.Cu)}
                                        inputProps={{ step: 0.01 }}
                                        type="number"
                                        endAdornment={
                                          <InputAdornment position="end">
                                            <Box mr={1}>[kg Cu / kg]</Box>
                                            <PopoverCustom info={"Cooper content"} />
                                          </InputAdornment>
                                        }
                                      />
                                      {
                                        touched.heavy_metal_content?.Cu && errors.heavy_metal_content?.Cu && (
                                          <FormHelperText error>
                                            {errors.heavy_metal_content?.Cu}
                                          </FormHelperText>
                                        )
                                      }
                                    </FormControl>
                                  </Grid>

                                  <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <FormControl fullWidth variant="outlined">
                                      <InputLabel size="small" htmlFor="heavy_metal_content.Zn">
                                        Zn content
                                      </InputLabel>
                                      <OutlinedInput
                                        fullWidth
                                        size="small"
                                        name="heavy_metal_content.Zn"
                                        id="heavy_metal_content.Zn"
                                        label="Zn content"
                                        value={values.heavy_metal_content?.Zn || ""}
                                        onChange={handleChange}
                                        onKeyDown={numericOnly}
                                        error={touched.heavy_metal_content?.Zn && Boolean(errors.heavy_metal_content?.Zn)}
                                        inputProps={{ step: 0.01 }}
                                        type="number"
                                        endAdornment={
                                          <InputAdornment position="end">
                                            <Box mr={1}>[kg Zn / kg]</Box>
                                            <PopoverCustom info={"Zinc content"} />
                                          </InputAdornment>
                                        }
                                      />
                                      {
                                        touched.heavy_metal_content?.Zn && errors.heavy_metal_content?.Zn && (
                                          <FormHelperText error>
                                            {errors.heavy_metal_content?.Zn}
                                          </FormHelperText>
                                        )
                                      }
                                    </FormControl>
                                  </Grid>

                                  <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <FormControl fullWidth variant="outlined">
                                      <InputLabel size="small" htmlFor="heavy_metal_content.Pb">
                                        Pb content
                                      </InputLabel>
                                      <OutlinedInput
                                        fullWidth
                                        size="small"
                                        name="heavy_metal_content.Pb"
                                        id="heavy_metal_content.Pb"
                                        label="Pb content"
                                        value={values.heavy_metal_content?.Pb || ""}
                                        onChange={handleChange}
                                        onKeyDown={numericOnly}
                                        error={touched.heavy_metal_content?.Pb && Boolean(errors.heavy_metal_content?.Pb)}
                                        inputProps={{ step: 0.01 }}
                                        type="number"
                                        endAdornment={
                                          <InputAdornment position="end">
                                            <Box mr={1}>[kg Pb / kg]</Box>
                                            <PopoverCustom info={"Lead content"} />
                                          </InputAdornment>
                                        }
                                      />
                                      {
                                        touched.heavy_metal_content?.Pb && errors.heavy_metal_content?.Pb && (
                                          <FormHelperText error>
                                            {errors.heavy_metal_content?.Pb}
                                          </FormHelperText>
                                        )
                                      }
                                    </FormControl>
                                  </Grid>

                                  <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <FormControl fullWidth variant="outlined">
                                      <InputLabel size="small" htmlFor="heavy_metal_content.Ni">
                                        Ni content
                                      </InputLabel>
                                      <OutlinedInput
                                        fullWidth
                                        size="small"
                                        name="heavy_metal_content.Ni"
                                        id="heavy_metal_content.Ni"
                                        label="Ni content"
                                        value={values.heavy_metal_content?.Ni || ""}
                                        onChange={handleChange}
                                        onKeyDown={numericOnly}
                                        error={touched.heavy_metal_content?.Ni && Boolean(errors.heavy_metal_content?.Ni)}
                                        inputProps={{ step: 0.01 }}
                                        type="number"
                                        endAdornment={
                                          <InputAdornment position="end">
                                            <Box mr={1}>[kg Ni / kg]</Box>
                                            <PopoverCustom info={"Nickel content"} />
                                          </InputAdornment>
                                        }
                                      />
                                      {
                                        touched.heavy_metal_content?.Ni && errors.heavy_metal_content?.Ni && (
                                          <FormHelperText error>
                                            {errors.heavy_metal_content?.Ni}
                                          </FormHelperText>
                                        )
                                      }
                                    </FormControl>
                                  </Grid>

                                  <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <FormControl fullWidth variant="outlined">
                                      <InputLabel size="small" htmlFor="heavy_metal_content.Cr">
                                        Cr content
                                      </InputLabel>
                                      <OutlinedInput
                                        fullWidth
                                        size="small"
                                        name="heavy_metal_content.Cr"
                                        id="heavy_metal_content.Cr"
                                        label="Cr content"
                                        value={values.heavy_metal_content?.Cr || ""}
                                        onChange={handleChange}
                                        onKeyDown={numericOnly}
                                        error={touched.heavy_metal_content?.Cr && Boolean(errors.heavy_metal_content?.Cr)}
                                        inputProps={{ step: 0.01 }}
                                        type="number"
                                        endAdornment={
                                          <InputAdornment position="end">
                                            <Box mr={1}>[kg Cr / kg]</Box>
                                            <PopoverCustom info={"Chromium content"} />
                                          </InputAdornment>
                                        }
                                      />
                                      {
                                        touched.heavy_metal_content?.Cr && errors.heavy_metal_content?.Cr && (
                                          <FormHelperText error>
                                            {errors.heavy_metal_content?.Cr}
                                          </FormHelperText>
                                        )
                                      }
                                    </FormControl>
                                  </Grid>

                                  <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <FormControl fullWidth variant="outlined">
                                      <InputLabel size="small" htmlFor="heavy_metal_content.Hg">
                                        Hg content
                                      </InputLabel>
                                      <OutlinedInput
                                        fullWidth
                                        size="small"
                                        name="heavy_metal_content.Hg"
                                        id="heavy_metal_content.Hg"
                                        label="Hg content"
                                        value={values.heavy_metal_content?.Hg || ""}
                                        onChange={handleChange}
                                        onKeyDown={numericOnly}
                                        error={touched.heavy_metal_content?.Hg && Boolean(errors.heavy_metal_content?.Hg)}
                                        inputProps={{ step: 0.01 }}
                                        type="number"
                                        endAdornment={
                                          <InputAdornment position="end">
                                            <Box mr={1}>[kg Hg / kg]</Box>
                                            <PopoverCustom info={"Mercury content"} />
                                          </InputAdornment>
                                        }
                                      />
                                      {
                                        touched.heavy_metal_content?.Hg && errors.heavy_metal_content?.Hg && (
                                          <FormHelperText error>
                                            {errors.heavy_metal_content?.Hg}
                                          </FormHelperText>
                                        )
                                      }
                                    </FormControl>
                                  </Grid>

                                  <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <FormControl fullWidth variant="outlined">
                                      <InputLabel size="small" htmlFor="heavy_metal_content.As">
                                        As content
                                      </InputLabel>
                                      <OutlinedInput
                                        fullWidth
                                        size="small"
                                        name="heavy_metal_content.As"
                                        id="heavy_metal_content.As"
                                        label="As content"
                                        value={values.heavy_metal_content?.As || ""}
                                        onChange={handleChange}
                                        onKeyDown={numericOnly}
                                        error={touched.heavy_metal_content?.As && Boolean(errors.heavy_metal_content?.As)}
                                        inputProps={{ step: 0.01 }}
                                        type="number"
                                        endAdornment={
                                          <InputAdornment position="end">
                                            <Box mr={1}>[kg As / kg]</Box>
                                            <PopoverCustom info={"Arsenic content"} />
                                          </InputAdornment>
                                        }
                                      />
                                      {
                                        touched.heavy_metal_content?.As && errors.heavy_metal_content?.As && (
                                          <FormHelperText error>
                                            {errors.heavy_metal_content?.As}
                                          </FormHelperText>
                                        )
                                      }
                                    </FormControl>
                                  </Grid>
                                </>
                              ) 
                              : null
                            }

                            {
                              values.main_type === "fertiliser" && values.type === "mineral, generic" 
                              ? (
                                <>
                                  <Grid item xs={12} sm={12} md={12} lg={12}>
                                    Source
                                  </Grid>

                                  <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <TextField
                                      fullWidth
                                      size="small"
                                      name="source.N"
                                      select
                                      label="Source Nitrogen"
                                      value={values.source?.N || ""}
                                      onChange={handleChange}
                                      error={Boolean(errors.source?.N)}
                                      helperText={errors.source?.N}
                                    >
                                      <MenuItem key={'none'} value={""}>
                                        {'None'}
                                      </MenuItem>
                                      {
                                        fertiliserSourcesN.registros.map((option) => (
                                          <MenuItem key={option} value={option}>
                                            {option}
                                          </MenuItem>
                                        ))
                                      }
                                    </TextField>
                                  </Grid>

                                  <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <TextField
                                      fullWidth
                                      size="small"
                                      name="source.P"
                                      select
                                      label="Source Phosphorus"
                                      value={values.source?.P || ""}
                                      onChange={handleChange}
                                      error={Boolean(errors.source?.P)}
                                      helperText={errors.source?.P}
                                    >
                                      <MenuItem key={'none'} value={""}>
                                        {'None'}
                                      </MenuItem>
                                      {
                                        fertiliserSourcesP.registros.map((option) => (
                                          <MenuItem key={option} value={option}>
                                            {option}
                                          </MenuItem>
                                        ))
                                      }
                                    </TextField>
                                  </Grid>

                                  <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <TextField
                                      fullWidth
                                      size="small"
                                      name="source.K"
                                      select
                                      label="Source Potassium"
                                      value={values.source?.K || ""}
                                      onChange={handleChange}
                                      error={Boolean(errors.source?.K)}
                                      helperText={errors.source?.K}
                                    >
                                      <MenuItem key={'none'} value={""}>
                                        {'None'}
                                      </MenuItem>
                                      {
                                        fertiliserSourcesK.registros.map((option) => (
                                          <MenuItem key={option} value={option}>
                                            {option}
                                          </MenuItem>
                                        ))
                                      }
                                    </TextField>
                                  </Grid>
                                </>
                              ) 
                              : null
                            }

                            {
                              values.main_type === "pesticide" && values.type === "generic" 
                              ? (
                                <Grid item xs={12}>
                                  <Grid item xs={12} sm={12} md={12} lg={12}>
                                    Active Principles
                                  </Grid>

                                  <Box sx={{ px: 1, py: 3 }}>
                                    <FieldArray name="active_principles">
                                      {({ push, remove }) => (
                                        <Box>
                                          {
                                            values.active_principles && values?.active_principles.map((ap, index) => {
                                              const name = `active_principles[${index}].name`;
                                              const touchedName = getIn(touched, name);
                                              const errorName = getIn(errors, name);
                                              const amount = `active_principles[${index}].amount`;
                                              const touchedAmount = getIn(touched, amount);
                                              const errorAmount = getIn(errors, amount);
                                              const unit = `active_principles[${index}].unit`;
                                              const touchedUnit = getIn(touched, unit);
                                              const errorUnit = getIn(errors, unit);
                                              return (
                                                <Box key={index} display={"flex"} flexDirection={"row"} alignItems={"center"} gap={1} mb={1}>
                                                  
                                                  <Box>{index + 1}</Box>

                                                  <Grid item xs={12} sm={6} md={4} lg={6}>
                                                    <Autocomplete
                                                      size="small"
                                                      id="activePrinciples"
                                                      name={name}
                                                      isOptionEqualToValue={(option, value) => option === value}
                                                      options={pesticideActivePrinciplesNames.registros}
                                                      onChange={(e, value) => setFieldValue(name, value || "")}
                                                      value={ap.name || ""}
                                                      renderInput={(params) => (
                                                        <TextField
                                                          {...params}
                                                          autoFocus
                                                          label="Name"
                                                          error={touchedName && Boolean(errorName)}
                                                          helperText={touchedName && errorName}
                                                        />
                                                      )}
                                                    />
                                                  </Grid>

                                                  <Grid item xs={12} sm={6} md={4} lg={4}>
                                                    <FormControl fullWidth variant="outlined">
                                                      <InputLabel size="small" htmlFor="heavy_metal_content.Cd">
                                                        Amount
                                                      </InputLabel>
                                                      <OutlinedInput
                                                        fullWidth
                                                        size="small"
                                                        name={amount}
                                                        id={amount}
                                                        label="Amount"
                                                        value={ap.amount || ""}
                                                        onChange={handleChange}
                                                        onKeyDown={numericOnly}
                                                        error={touchedAmount && Boolean(errorAmount)}
                                                        inputProps={{ step: 0.01 }}
                                                        type="number"
                                                      />
                                                      {
                                                        touchedAmount && errorAmount && (
                                                          <FormHelperText error>
                                                            {errorAmount}
                                                          </FormHelperText>
                                                        )
                                                      }
                                                    </FormControl>
                                                  </Grid>

                                                  <Grid item xs={12} sm={6} md={4} lg={6}>
                                                    <Autocomplete
                                                      size="small"
                                                      id={`active_principles[${index}].unit`}
                                                      name={`active_principles[${index}].unit`}
                                                      options={pesticideActivePrincipleUnits.registros}
                                                      onChange={(e, value) => {handleChangeActivePrinciplesUnit(values, value, setFieldValue, index);}}
                                                      isOptionEqualToValue={(option, value) => option === value}
                                                      value={values.active_principles[index].unit || ""}
                                                      renderInput={(params) => (
                                                        <TextField
                                                          required
                                                          {...params}
                                                          label="Unit"
                                                          error={touchedUnit && Boolean(errorUnit)}
                                                          helperText={touchedUnit && errorUnit}
                                                        />
                                                      )}
                                                    />
                                                  </Grid>
                                                  
                                                  <IconButton onClick={() => remove(index)}>
                                                    <RemoveCircleOutlineRoundedIcon />
                                                  </IconButton>

                                                </Box>
                                              );
                                            })
                                          }
                                          <Button
                                            type="button"
                                            variant="outlined"
                                            onClick={() =>
                                              push({ name: "", amount: "", unit: "" })
                                            }
                                            startIcon={<AddCircleOutlineRoundedIcon />}
                                          >
                                            Add Active Principle
                                          </Button>
                                        </Box>
                                      )}
                                    </FieldArray>
                                  </Box>

                                </Grid>
                              ) 
                              : null
                            }
                          </Grid>

                        </DialogContent>
                      )
                    }

                    
                    <DialogActions>
                      <Box sx={{ display: "flex", gap: 1 }}>

                        <Button
                          size="small"
                          onClick={handleClose}
                          variant="outlined"
                          startIcon={<CancelRoundedIcon />}
                        >
                          Cancel
                        </Button>

                        <Button
                          size="small"
                          variant="contained"
                          startIcon={<SaveRoundedIcon />}
                          autoFocus
                          type="submit"
                        >
                          save
                        </Button>

                      </Box>
                    </DialogActions>

                  </Form>
                );
              }}
            </Formik>
          )
        }

      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    fertiliserType: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationProductFertiliserType,
      error: state.enumerated.error,
    },
    pesticideActivePrinciplesNames: {
      loading: state.enumerated.loading,
      registros:
        state.enumerated.enumerationProductPesticideActivePrinciplesNames,
      error: state.enumerated.error,
    },
    fertiliserNpk: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationFertiliserNpk,
      error: state.enumerated.error,
    },
    fertiliserSourcesN: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationFertiliserSourcesN,
      error: state.enumerated.error,
    },
    fertiliserSourcesP: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationFertiliserSourcesP,
      error: state.enumerated.error,
    },
    fertiliserSourcesK: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationFertiliserSourcesK,
      error: state.enumerated.error,
    },
    pesticideActivePrincipleUnits: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationPesticideActivePrincipleUnits,
      error: state.enumerated.error,
    },
    waterNames: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationWaterNames,
      error: state.enumerated.error,
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEnumerationPesticideType: () => dispatch(fetchEnumerationPesticideType()),
    fetchEnumerationProductFertiliserType: () => dispatch(fetchEnumerationProductFertiliserType()),
    fetchEnumerationProductFertiliserSolidManureNames: () => dispatch(fetchEnumerationProductFertiliserSolidManureNames()),
    fetchEnumerationProductFertiliserLiquidManureNames: () => dispatch(fetchEnumerationProductFertiliserLiquidManureNames()),
    fetchEnumerationProductPesticideFromTableNames: () => dispatch(fetchEnumerationProductPesticideFromTableNames()),
    fetchEnumerationProductPesticideActivePrinciplesNames: () => dispatch(fetchEnumerationProductPesticideActivePrinciplesNames()),
    fetchEnumerationProductCorretiveName: () => dispatch(fetchEnumerationProductCorretiveName()),
    fetchEnumerationFertiliserName: () => dispatch(fetchEnumerationFertiliserName()),
    fetchEnumerationFertiliserNpk: (name) => dispatch(fetchEnumerationFertiliserNpk(name)),
    editProduct: (params) => dispatch(editProduct(params)),
    fetchCheckPesticideFromTableHasDensity: (pest_name) => dispatch(fetchCheckPesticideFromTableHasDensity(pest_name)),
    fetchEnumerationFertiliserSourcesN: () => dispatch(fetchEnumerationFertiliserSourcesN()),
    fetchEnumerationFertiliserSourcesP: () => dispatch(fetchEnumerationFertiliserSourcesP()),
    fetchEnumerationFertiliserSourcesK: () => dispatch(fetchEnumerationFertiliserSourcesK()),
    fetchEnumerationPesticideActivePrincipleUnits: () => dispatch(fetchEnumerationPesticideActivePrincipleUnits()),
    fetchEnumerationWaterNames: () => dispatch(fetchEnumerationWaterNames())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsFormEdit);
