import { Alert, Slide, Snackbar } from '@mui/material'
import React from 'react'

function SnackbarCustom(props) {

  const {open, setOpen, msg, severity="warning" } = props

  const handleClose = () => {
    setOpen(false)
  }

  function TransitionLeft(props) {
    return <Slide {...props} direction="down" />;
  }

  return (
    <Snackbar 
      open={open} 
      autoHideDuration={6000} 
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      //sx={{border:'5px solid white', borderRadius:'5px', opacity:0.8}}
      TransitionComponent={TransitionLeft}
    >
      <Alert 
        onClose={handleClose} 
        variant="filled" 
        //variant="outlined"
        severity={severity} 
        sx={{ maxWidth: '500px'}}

      >
        <strong>{msg}</strong>
      </Alert>
    </Snackbar>
  )
}

export default SnackbarCustom
