// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dot-label>span {
    height: 20px!important;
    width: 20px!important;
    bottom: -9px!important;
}

.horizontal-timeline>div>div {
    background-color: #F6FCF6;
}

`, "",{"version":3,"sources":["webpack://./src/components/properties/plot/styleTimeLineHorizontal.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,qBAAqB;IACrB,sBAAsB;AAC1B;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".dot-label>span {\r\n    height: 20px!important;\r\n    width: 20px!important;\r\n    bottom: -9px!important;\r\n}\r\n\r\n.horizontal-timeline>div>div {\r\n    background-color: #F6FCF6;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
