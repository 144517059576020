// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiInputAdornment-root .MuiTypography-root {
    font-size: 0.85rem
}
.MuiInputAdornment-root .MuiBox-root {
    font-size: 0.75rem
}`, "",{"version":3,"sources":["webpack://./src/components/machinery/machineryCss.css"],"names":[],"mappings":"AAAA;IACI;AACJ;AACA;IACI;AACJ","sourcesContent":[".MuiInputAdornment-root .MuiTypography-root {\r\n    font-size: 0.85rem\r\n}\r\n.MuiInputAdornment-root .MuiBox-root {\r\n    font-size: 0.75rem\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
