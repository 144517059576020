import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import api from './api'
import SnackbarCustom from '../components/layout/SnackbarCustom'

export const ResponseInterceptor = () => {

  const [open, setOpen] = useState(false)
  const [msg, setMsg] = useState('')

  const navigate = useNavigate()

  const interceptorId = useRef(null)

  useEffect(()=>{
    interceptorId.current = api.interceptors.response.use(
      (response) => { return response }, 
      (error) => {
        let msg = ''
        
        typeof error.response.data?.detail === "string" 
          ? msg = error.response.data?.detail 
          : msg = error.response.data.message
        
        switch(error.response.status){

          case 401:
            setOpen(true)
            setMsg(msg)
            navigate('/')
            break
          case 404:
          case 409:
          case 422:
            setOpen(true)
            setMsg(msg)
            break
          default:
            return
        }
      }
    )

    return () => {
      api.interceptors.response.eject(interceptorId.current)
    }
  },[navigate])

  return (
    <>
    {
      open
      ? (
        <SnackbarCustom 
          open={open}
          setOpen={setOpen}
          msg={msg}
          sx={{zIndex:99}}
        />
      )
      : null
    }
    </>
  )
}