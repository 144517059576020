import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import validationSchema from "./plotValidationSchema";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import PopoverCustom from "../../layout/PopoverCustom";
import {
  fetchEnumerationPlotCropName,
  fetchEnumerationPlotLoadTreatment,
  fetchEnumerationPlotTransformFrom,
  fetchEnumerationPlotType,
  fetchEnumerationSeedType,
  fetchEnumerationSeedUnitSeedling,
} from "../../../redux/enumeratedSlice";
import {
  addPlot,
  checkActivityPeriodIsNotConflicted,
  checkActivityStartDateIsNotConflicted,
  editPlotActivity,
  fetchUserAll,
} from "../../../redux/userAllSlice";
import { useParams } from "react-router-dom";
import { numericOnly, numericField } from "../../../hooks/functions";
import CloseIcon from "@mui/icons-material/CancelRounded";

export const PlotForm = (props) => {

  let { id } = useParams();
  
  const plot_ter_id = id;
  const {
    openPlotForm,
    setOpenPlotForm,
    plot_terrain,
    fetchEnumerationPlotType,
    plotType,
    fetchEnumerationSeedType,
    seedType,
    fetchEnumerationSeedUnitSeedling,
    seedUnitSeedling,
    fetchEnumerationPlotTransformFrom,
    transformFrom,
    fetchEnumerationPlotLoadTreatment,
    loadTreatment,
    fetchEnumerationPlotCropName,
    cropName,
    addPlot,
    mode,
    setMode,
    plotActivitySelected,
    editPlotActivity,
    checkActivityStartDateIsNotConflicted,
    checkActivityPeriodIsNotConflicted,
  } = props;

  // eslint-disable-next-line no-unused-vars
  const [cropType, setCropType] = useState("");
  const [cropNameSelected, setCropNameSelected] = useState("");

  const property = JSON.parse(localStorage.getItem("property"));

  useEffect(() => {
    fetchEnumerationPlotType();
  }, [fetchEnumerationPlotType]);

  useEffect(() => {
    if (mode === "add") {
      cropNameSelected && fetchEnumerationSeedType(cropNameSelected);
    } else {
      plotActivitySelected.crop &&  fetchEnumerationSeedType(plotActivitySelected.crop.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cropNameSelected, mode]);

  useEffect(() => {
    fetchEnumerationSeedUnitSeedling();
  }, [fetchEnumerationSeedUnitSeedling]);

  useEffect(() => {
    fetchEnumerationPlotTransformFrom();
  }, [fetchEnumerationPlotTransformFrom]);

  useEffect(() => {
    fetchEnumerationPlotLoadTreatment();
  }, [fetchEnumerationPlotLoadTreatment]);

  useEffect(() => {
    fetchEnumerationPlotCropName();
  }, [fetchEnumerationPlotCropName]);

  const initialValues = {
    _id: plotActivitySelected._id || "",
    plot_Terrains_id: plot_terrain?._id,
    type: plotActivitySelected.type || "",
    transform_from: plotActivitySelected.transform_from || "",
    load_treatment: plotActivitySelected.load_treatment || "",
    start_date: plotActivitySelected.start_date || "",
    period: plotActivitySelected.period || "",
    crop: {
      name: plotActivitySelected.crop?.name || "",
      seed: {
        type: plotActivitySelected.crop?.seed?.type || "",
        amount: plotActivitySelected.crop?.seed?.amount || "",
        unit: plotActivitySelected.crop?.seed?.unit || "",
      },
      exp_yield: plotActivitySelected.crop?.exp_yield || "",
      root_depth: plotActivitySelected.crop?.root_depth || "",
      n_uptake: plotActivitySelected.crop?.n_uptake || "",
      NAG: plotActivitySelected.crop?.NAG || "",
      NBG: plotActivitySelected.crop?.NBG || "",
      RAG: plotActivitySelected.crop?.RAG || "",
      RS: plotActivitySelected.crop?.RS || "",
      DRY: plotActivitySelected.crop?.DRY || "",
      renew_factor: plotActivitySelected.crop?.renew_factor || "",
      remove_factor: plotActivitySelected.crop?.remove_factor || "",
      dry_mass_carbon: plotActivitySelected.crop?.dry_mass_carbon || "",
      dry_mass_heavy_metal_content: {
        Cd: plotActivitySelected.crop?.dry_mass_heavy_metal_content?.Cd || "",
        Cu: plotActivitySelected.crop?.dry_mass_heavy_metal_content?.Cu || "",
        Zn: plotActivitySelected.crop?.dry_mass_heavy_metal_content?.Zn || "",
        Pb: plotActivitySelected.crop?.dry_mass_heavy_metal_content?.Pb || "",
        Ni: plotActivitySelected.crop?.dry_mass_heavy_metal_content?.Ni || "",
        Cr: plotActivitySelected.crop?.dry_mass_heavy_metal_content?.Cr || "",
        Hg: plotActivitySelected.crop?.dry_mass_heavy_metal_content?.Hg || "",
        As: plotActivitySelected.crop?.dry_mass_heavy_metal_content?.As || "",
      },
      planting_period: plotActivitySelected.crop?.planting_period || "",
    },
  };

  const handleClose = () => {
    setOpenPlotForm(false);
    setMode("add");
  };

  const handleChangeCropType = (event, setFieldValue, resetForm) => {
    resetForm();
    setFieldValue("type", event.target.value);
    setCropType(event.target.value);
  };

  const handleChangeCropName = (event, setFieldValue) => {
    setFieldValue("crop.name", event.target.value);
    setCropNameSelected(event.target.value);
  };

  let params = {};

  const handleSubmit = async (values) => {
    let seedValidated = null;
    if (
      values.crop.seed.type !== "" ||
      values.crop.seed.amount !== "" ||
      values.crop.seed.unit !== ""
    ) {
      seedValidated = {
        type: values.crop.seed.type === "" ? null : values.crop.seed.type,
        amount: values.crop.seed.amount === "" ? null : values.crop.seed.amount,
        unit: values.crop.seed.type === "seedling" ? values.crop.seed.unit : null,
        //unit: values.crop.seed.type === "" ? null :  values.crop.seed.unit,
      };
    }

    let heavyMetalValidated = null;
    if (
      values.crop.dry_mass_heavy_metal_content.Cd !== "" ||
      values.crop.dry_mass_heavy_metal_content.Cu !== "" ||
      values.crop.dry_mass_heavy_metal_content.Zn !== "" ||
      values.crop.dry_mass_heavy_metal_content.Pb !== "" ||
      values.crop.dry_mass_heavy_metal_content.Ni !== "" ||
      values.crop.dry_mass_heavy_metal_content.Cr !== "" ||
      values.crop.dry_mass_heavy_metal_content.Hg !== "" ||
      values.crop.dry_mass_heavy_metal_content.As !== ""
    ) {
      heavyMetalValidated = {
        Cd: values.crop.dry_mass_heavy_metal_content.Cd === "" ? null : values.crop.dry_mass_heavy_metal_content.Cd,
        Cu: values.crop.dry_mass_heavy_metal_content.Cu === "" ? null : values.crop.dry_mass_heavy_metal_content.Cu,
        Zn: values.crop.dry_mass_heavy_metal_content.Zn === "" ? null : values.crop.dry_mass_heavy_metal_content.Zn,
        Pb: values.crop.dry_mass_heavy_metal_content.Pb === "" ? null : values.crop.dry_mass_heavy_metal_content.Pb,
        Ni: values.crop.dry_mass_heavy_metal_content.Ni === "" ? null : values.crop.dry_mass_heavy_metal_content.Ni,
        Cr: values.crop.dry_mass_heavy_metal_content.Cr === "" ? null : values.crop.dry_mass_heavy_metal_content.Cr,
        Hg: values.crop.dry_mass_heavy_metal_content.Hg === "" ? null : values.crop.dry_mass_heavy_metal_content.Hg,
        As: values.crop.dry_mass_heavy_metal_content.As === "" ? null : values.crop.dry_mass_heavy_metal_content.As,
      };
    }

    let cropValidated = null;
    if (
      values.crop.name !== "" ||
      values.crop.exp_yield !== "" ||
      values.crop.root_depth !== "" ||
      values.crop.planting_period !== ""
    ) {
      cropValidated = {
        name: values.crop.name === "" ? null : values.crop.name,
        seed: seedValidated ? seedValidated : null,
        dry_mass_heavy_metal_content: heavyMetalValidated ? heavyMetalValidated : null,
        exp_yield: values.crop.exp_yield === "" ? null : values.crop.exp_yield,
        root_depth: values.crop.root_depth === "" ? null : values.crop.root_depth,
        planting_period: values.crop.planting_period === "" ? null : parseInt(values.crop.planting_period),
        n_uptake: values.crop.n_uptake === "" ? null : values.crop.n_uptake,
        NAG: values.crop.NAG === "" ? null : values.crop.NAG,
        NBG: values.crop.NBG === "" ? null : values.crop.NBG,
        RAG: values.crop.RAG === "" ? null : values.crop.RAG,
        RS: values.crop.RS === "" ? null : values.crop.RS,
        DRY: values.crop.DRY === "" ? null : values.crop.DRY,
        renew_factor: values.crop.renew_factor === "" ? null : values.crop.renew_factor,
        remove_factor: values.crop.remove_factor === "" ? null : values.crop.remove_factor,
        dry_mass_carbon: values.crop.dry_mass_carbon === "" ? null : values.crop.dry_mass_carbon,
      };
    }

    if (values.type === "crop") {
      params = {
        prop_id: property._id,
        plot_ter_id: plot_ter_id,
        plot_id: plotActivitySelected ? plotActivitySelected._id : null,
        payload: {
          type: values.type,
          transform_from: values.transform_from,
          load_treatment: values.load_treatment,
          start_date: values.start_date,
          crop: cropValidated,
        },
      };
    } else {
      params = {
        prop_id: property._id,
        plot_ter_id: plot_ter_id,
        plot_id: plotActivitySelected ? plotActivitySelected._id : null,
        payload: {
          type: values.type,
          transform_from: values.transform_from,
          load_treatment: values.load_treatment,
          start_date: values.start_date,
          period: values.period === "" ? null : parseInt(values.period),
          crop: cropValidated,
        },
      };
    }

    if (mode === "add") {
      await addPlot(params);
    }

    if (mode === "edit") {
      await editPlotActivity(params);
    }

    handleClose();
  };

  const handleChangeSeedType = (event, setFieldValue, values) => {
    setFieldValue("crop.seed.type", event.target.value);
    setFieldValue("crop.seed.unit", "kg/ha");
  };

  const handleChangeStartDate = async (e, setFieldValue, setFieldError) => {
    const startDate = e.target.value;
    setFieldValue("start_date", startDate);
    const params = {
      start_date:
        startDate.substring(8) +
        "-" +
        startDate.substring(5, 7) +
        "-" +
        startDate.substring(0, 4),
      plot_ter_id: plot_ter_id,
      plot_id: plotActivitySelected ? plotActivitySelected._id : null,
    };
    const result = await checkActivityStartDateIsNotConflicted(params);
    if (result.payload?.error) {
      setFieldError("start_date", result.payload.message);
    }
  };

  const handleChangePeriod = async ( e, setFieldValue, setFieldError, values ) => {
    const period = e.target.value;
    setFieldValue('period', period)
    const startDate = values.start_date.substring(8) + "-" + values.start_date.substring(5, 7) + "-" + values.start_date.substring(0, 4);
    const params = {
      start_date: startDate,
      period: period,
      plot_ter_id: plot_ter_id,
      plot_id: plotActivitySelected ? plotActivitySelected._id : null,
    };
    const result = await checkActivityPeriodIsNotConflicted(params);
    if (result?.payload?.error) {
      setFieldError("period", result.payload.message.detail);
    } 
  };

  const handleChangeCropPeriod = async (e, values, setFieldError, setFieldValue) => {
    const crop_period = e.target.value;
    setFieldValue('crop.planting_period', crop_period)
    const startDate = values.start_date.substring(8) + "-" + values.start_date.substring(5, 7) + "-" + values.start_date.substring(0, 4);
    //// Verificar conflito de datas e períodos
    const params = {
      start_date: startDate,
      period: crop_period,
      plot_ter_id: plot_ter_id,
      plot_id: plotActivitySelected ? plotActivitySelected._id : null,
    };
    const result = await checkActivityPeriodIsNotConflicted(params);
    if (result?.payload?.error) {
      setFieldError("crop.planting_period", result.payload.message.detail);
    }
  };

  async function init(){
    setTimeout(() => {
      numericField()
    }, 500);
  }

  useEffect(()=> {
    init()
  }, [cropType])

  return (
    <div>
      <Dialog
        open={openPlotForm}
        onClose={handleClose}
        maxWidth="md"
        scroll="body"
      >
        <DialogTitle
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          {mode === "add" ? "Add " : "Edit "}Plot Activity
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "green",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <Typography
          variant="body2"
          sx={{ ml: 3, mt: 1, color: "red", fontWeight: "bold" }}
        >
          * Required Fields
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({ values, touched, errors, handleChange, setFieldValue, resetForm, setFieldError, isValid,}) => {
            return (
              <Form noValidate autoComplete="off">
                <DialogContent sx={{ minWidth: { lg: "800px", md: "600px", sm: "400px" } }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <TextField
                        required
                        fullWidth
                        size="small"
                        name="type"
                        select
                        label="Type"
                        value={values.type || ''}
                        onChange={(event) => handleChangeCropType(event, setFieldValue, resetForm)}
                        error={touched?.type && Boolean(errors?.type)}
                        helperText={touched?.type && errors?.type}
                      >
                        {
                          plotType.registros.map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))
                        }
                      </TextField>
                    </Grid>
                    {values.type !== "" && (
                      <>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                          <TextField
                            required
                            fullWidth
                            size="small"
                            name="start_date"
                            label="Start Date"
                            type="date"
                            value={values.start_date}
                            onChange={(e) => handleChangeStartDate( e, setFieldValue, setFieldError)}
                            InputLabelProps={{ shrink: true }}
                            error={Boolean(errors.start_date)}
                            helperText={errors.start_date}
                            InputProps={{
                              endAdornment: (
                                <Box>
                                  <PopoverCustom info={"Starting date of plot activity"}/>
                                </Box>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                          <Autocomplete
                            size="small"
                            id="transform_from"
                            name="transform_from"
                            options={transformFrom.registros}
                            onChange={(event, value) => setFieldValue("transform_from", value)}
                            value={values.transform_from || null}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                required
                                label="Land Use Before"
                                error={touched.transform_from && Boolean(errors.transform_from)}
                                helperText={touched.transform_from && errors.transform_from}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <>
                                      <InputAdornment position="end">
                                        <PopoverCustom info={"Usage type of the land before this activity"}/>
                                      </InputAdornment>
                                      {params.InputProps.endAdornment}
                                    </>
                                  ),
                                }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                          <Autocomplete
                            size="small"
                            id="load_treatment"
                            name="load_treatment"
                            options={loadTreatment.registros}
                            onChange={(event, value) => setFieldValue("load_treatment", value)}
                            value={values.load_treatment || null}
                            renderInput={(params) => (
                              <TextField
                                required
                                {...params}
                                label="Allocation Load Treatment "
                                error={touched.load_treatment && Boolean(errors.load_treatment)}
                                helperText={touched.load_treatment && errors.load_treatment}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <>
                                      <InputAdornment position="end">
                                        <PopoverCustom info={"Type of allocation load treatment of this activity. Donate Loads will redistribute its impacts to other commercial crops, for example a cover crop emission can be redistributed to other crops. Receive Loads will receive the loads donated by other crops, for example a commercial crop receives the emissions of a previous cover crop. Neutral will not participate in that distribution. See Folegatti-Matsuura et al. (2022)"}/>
                                      </InputAdornment>
                                      {params.InputProps.endAdornment}
                                    </>
                                  ),
                                }}
                              />
                            )}
                          />
                        </Grid>
                        {values.type !== "crop" && (
                          <Grid item xs={12} sm={6} md={4} lg={4}>
                            <FormControl fullWidth variant="outlined">
                              <InputLabel
                                required
                                size="small"
                                htmlFor="period"
                                error={touched.period && Boolean(errors.period)}
                              >
                                Plot Activity Period
                              </InputLabel>
                              <OutlinedInput
                                size="small"
                                name="period"
                                id="period"
                                label="Plot Activity Period"
                                value={values.period || ""}
                                onChange={(e) =>{ handleChangePeriod(e, setFieldValue, setFieldError, values) }}
                                onKeyDown={numericOnly}
                                inputProps={{ step: 1, min: 1 }}
                                error={Boolean(errors.period)}
                                type="number"
                                endAdornment={
                                  <InputAdornment position="end">
                                    <Box>[days]</Box>
                                  </InputAdornment>
                                }
                              />
                              {
                                errors.period && (
                                  <FormHelperText error>
                                    {errors.period}
                                  </FormHelperText>
                                )
                              }
                            </FormControl>
                          </Grid>
                        )}
                      </>
                    )}
                    {
                      values.type !== "" &&
                      values.type !== "cover crop" &&
                      values.type !== "fallow" &&
                      values.type !== "intercrop" 
                      ? (
                        <>
                          <Grid container spacing={2} p={2} ml={2}>
                            <Grid
                              item
                              xs={12}
                              display={"flex"}
                              alignItems="center"
                              gap={2}
                              sx={{
                                backgroundColor: "#F5F5F5",
                                paddingBottom: 2,
                                marginLeft: 2,
                                marginTop: 1,
                                borderRadius: 3,
                              }}
                            >
                              <Typography
                                variant="subtitle"
                                sx={{ fontWeight: "bold" }}
                              >
                                Crop
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4}>
                              <TextField
                                required
                                fullWidth
                                size="small"
                                name="crop.name"
                                select
                                label="Crop name"
                                value={values.crop.name}
                                onChange={(e) =>
                                  handleChangeCropName(e, setFieldValue)
                                }
                                error={
                                  touched.crop?.name && Boolean(errors.crop?.name)
                                }
                                helperText={
                                  touched.crop?.name && errors.crop?.name
                                }
                              >
                                {cropName.registros.map((option) => (
                                  <MenuItem key={option} value={option}>
                                    {option}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4}>
                              <FormControl fullWidth variant="outlined">
                                <InputLabel
                                  required
                                  size="small"
                                  htmlFor="crop.exp_yield"
                                  error={ touched.crop?.exp_yield && Boolean(errors.crop?.exp_yield) }
                                >
                                  Yield
                                </InputLabel>
                                <OutlinedInput
                                  size="small"
                                  name="crop.exp_yield"
                                  id="crop.exp_yield"
                                  label="Yield"
                                  value={values.crop.exp_yield.toString() || ""}
                                  onChange={handleChange}
                                  onKeyDown={numericOnly}
                                  inputProps={{ step: 0.01, min: 0 }}
                                  error={ touched.crop?.exp_yield && Boolean(errors.crop?.exp_yield)}
                                  type="number"
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <Box mr={1}>[t / ha]</Box>
                                      <PopoverCustom info={"Crop yield"} />
                                    </InputAdornment>
                                  }
                                />
                                {touched.crop?.exp_yield &&
                                  errors.crop?.exp_yield && (
                                    <FormHelperText error>
                                      {errors.crop?.exp_yield}
                                    </FormHelperText>
                                  )}
                              </FormControl>
                            </Grid>
                            {values.type === "crop" && (
                              <Grid item xs={12} sm={6} md={4} lg={4}>
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel
                                    required
                                    size="small"
                                    htmlFor="crop.planting_period"
                                    error={
                                      touched.crop?.planting_period &&
                                      Boolean(errors.crop?.planting_period)
                                    }
                                  >
                                    Crop period
                                  </InputLabel>
                                  <OutlinedInput
                                    size="small"
                                    name="crop.planting_period"
                                    id="crop.planting_period"
                                    label="Crop period"
                                    value={values.crop.planting_period}
                                    onChange={(e) => { handleChangeCropPeriod(e, values, setFieldError, setFieldValue); }}
                                    onKeyDown={numericOnly}
                                    inputProps={{ step: 1, min: 1 }}
                                    error={Boolean(errors.crop?.planting_period)}
                                    type="number"
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <Box>[days]</Box>
                                      </InputAdornment>
                                    }
                                  />
                                  {errors.crop?.planting_period && (
                                    <FormHelperText error>
                                      {errors.crop?.planting_period}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              </Grid>
                            )}
                            <Grid item xs={12} sm={6} md={4} lg={4}>
                              <FormControl fullWidth variant="outlined">
                                <InputLabel size="small" htmlFor="crop.n_uptake">
                                  N Uptake
                                </InputLabel>
                                <OutlinedInput
                                  size="small"
                                  name="crop.n_uptake"
                                  id="crop.n_uptake"
                                  label="N Uptake"
                                  value={values.crop.n_uptake?.toString() || ""}
                                  onChange={handleChange}
                                  onKeyDown={numericOnly}
                                  inputProps={{ step: 0.01, min: 0 }}
                                  error={ touched.crop?.n_uptake && Boolean(errors.crop?.n_uptake)}
                                  type="number"
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <Box mr={1}>[kg N / ha]</Box>
                                      <PopoverCustom
                                        info={"Crop nitrogen uptake in the harvested fraction. See Nemecek et al. 2019, section 3.9.7.1. and 3.9.7.2"}
                                      />
                                    </InputAdornment>
                                  }
                                />
                                {touched.crop?.n_uptake &&
                                  errors.crop?.n_uptake && (
                                    <FormHelperText error>
                                      {errors.crop?.n_uptake}
                                    </FormHelperText>
                                  )}
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={8} lg={8}>
                              <FormControl fullWidth variant="outlined">
                                <InputLabel size="small" htmlFor="crop.NAG">
                                  Above-Ground Residue N Content
                                </InputLabel>
                                <OutlinedInput
                                  size="small"
                                  name="crop.NAG"
                                  id="crop.NAG"
                                  label="Above-Ground Residue N Content"
                                  value={values.crop.NAG.toString()  || ""}
                                  onChange={handleChange}
                                  //onKeyDown={numericOnly}
                                  inputProps={{ step: 0.01, min: 0, max: 1,}}
                                  error={ touched.crop?.NAG && Boolean(errors.crop?.NAG) }
                                  type="number"
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <Box mr={1}>[kg N / kg DM]</Box>
                                      <PopoverCustom info={"Nitrogen content of above-ground residues (per dry mass). See IPCC 2019"}/>
                                    </InputAdornment>
                                  }
                                />
                                {touched.crop?.NAG && errors.crop?.NAG && (
                                  <FormHelperText error>
                                    {errors.crop?.NAG}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                              <FormControl fullWidth variant="outlined">
                                <InputLabel size="small" htmlFor="crop.NBG">
                                  Below-Ground Residue N Content
                                </InputLabel>
                                <OutlinedInput
                                  size="small"
                                  name="crop.NBG"
                                  id="crop.NBG"
                                  label="Below-Ground Residue N Content"
                                  value={values.crop.NBG?.toString() || ""}
                                  onChange={handleChange}
                                  onKeyDown={numericOnly}
                                  inputProps={{ step: 0.01, min: 0, max: 1 }}
                                  error={ touched.crop?.NBG && Boolean(errors.crop?.NBG) }
                                  type="number"
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <Box mr={1}>[kg N / kg DM]</Box>
                                      <PopoverCustom info={"Nitrogen content of below-ground residues (per dry mass). See IPCC 2019"}/>
                                    </InputAdornment>
                                  }
                                />
                                {touched.crop?.NBG && errors.crop?.NBG && (
                                  <FormHelperText error>
                                    {errors.crop?.NBG}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                              <FormControl fullWidth variant="outlined">
                                <InputLabel
                                  size="small"
                                  htmlFor="crop.RAG"
                                  sx={{ fontSize: "0.9rem" }}
                                >
                                  RAG
                                </InputLabel>
                                <OutlinedInput
                                  size="small"
                                  name="crop.RAG"
                                  id="crop.RAG"
                                  label="RAG"
                                  value={values.crop.RAG?.toString() || ""}
                                  onChange={handleChange}
                                  onKeyDown={numericOnly}
                                  inputProps={{ step: 0.01, min: 0, max: 1 }}
                                  error={ touched.crop?.RAG && Boolean(errors.crop?.RAG) }
                                  type="number"
                                  
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <Box mr={1}>[kg DM / kg product]</Box>
                                      <PopoverCustom info={"Amount of dry mass above-ground Residue per harvested product"}/>
                                    </InputAdornment>
                                  }
                                />
                                {touched.crop?.RAG && errors.crop?.RAG && (
                                  <FormHelperText error>
                                    {errors.crop?.RAG}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                              <FormControl fullWidth variant="outlined">
                                <InputLabel size="small" htmlFor="crop.RS">
                                  RS
                                </InputLabel>
                                <OutlinedInput
                                  size="small"
                                  name="crop.RS"
                                  id="crop.RS"
                                  label="RS"
                                  value={values.crop.RS?.toString() || ""}
                                  onChange={handleChange}
                                  onKeyDown={numericOnly}
                                  inputProps={{ step: 0.01, min: 0, max: 1 }}
                                  error={ touched.crop?.RS && Boolean(errors.crop?.RS) }
                                  type="number"
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <Box mr={1}>[kg / kg]</Box>
                                      <PopoverCustom info={"Amount of below-ground (root) biomass per above-ground biomass"}/>
                                    </InputAdornment>
                                  }
                                />
                                {touched.crop?.RS && errors.crop?.RS && (
                                  <FormHelperText error>
                                    {errors.crop?.RS}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                              <FormControl fullWidth variant="outlined">
                                <InputLabel size="small" htmlFor="crop.DRY">
                                  DRY
                                </InputLabel>
                                <OutlinedInput
                                  size="small"
                                  name="crop.DRY"
                                  id="crop.DRY"
                                  label="DRY"
                                  value={values.crop.DRY?.toString() || ""}
                                  onChange={handleChange}
                                  onKeyDown={numericOnly}
                                  inputProps={{ step: 0.01, min: 0, max: 1 }}
                                  error={touched.crop?.DRY && Boolean(errors.crop?.DRY)}
                                  type="number"
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <Box sx={{ lineHeight: "1.2" }}>
                                        [kg DM / kg product]
                                      </Box>
                                    </InputAdornment>
                                  }
                                />
                                {touched.crop?.DRY && errors.crop?.DRY && (
                                  <FormHelperText error>
                                    {errors.crop?.DRY}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4}>
                              <FormControl fullWidth variant="outlined">
                                <InputLabel
                                  size="small"
                                  htmlFor="crop.renew_factor"
                                >
                                  Renewed Fraction
                                </InputLabel>
                                <OutlinedInput
                                  size="small"
                                  name="crop.renew_factor"
                                  id="crop.renew_factor"
                                  label="Renewed Fraction"
                                  value={values.crop.renew_factor?.toString() || ""}
                                  onChange={handleChange}
                                  onKeyDown={numericOnly}
                                  inputProps={{ step: 0.01, min: 0, max: 1 }}
                                  error={ touched.crop?.renew_factor && Boolean(errors.crop?.renew_factor) }
                                  type="number"
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <PopoverCustom info={"Fraction of total cropland area that is renewed. See IPCC 2019"}/>
                                    </InputAdornment>
                                  }
                                />
                                {touched.crop?.renew_factor &&
                                  errors.crop?.renew_factor && (
                                    <FormHelperText error>
                                      {errors.crop?.renew_factor}
                                    </FormHelperText>
                                  )}
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4}>
                              <FormControl fullWidth variant="outlined">
                                <InputLabel
                                  size="small"
                                  htmlFor="crop.remove_factor"
                                >
                                  Above-Ground Removed Fraction
                                </InputLabel>
                                <OutlinedInput
                                  size="small"
                                  name="crop.remove_factor"
                                  id="crop.remove_factor"
                                  label="Above-Ground Removed Fraction"
                                  value={values.crop.remove_factor?.toString() || ""}
                                  onChange={handleChange}
                                  onKeyDown={numericOnly}
                                  inputProps={{ step: 0.01, min: 0, max: 1 }}
                                  error={touched.crop?.remove_factor && Boolean(errors.crop?.remove_factor)}
                                  type="number"
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <PopoverCustom info={"Fraction of above-ground residues removed. See IPCC 2019"}/>
                                    </InputAdornment>
                                  }
                                />
                                {
                                  touched.crop?.remove_factor && errors.crop?.remove_factor && (
                                    <FormHelperText error>
                                      {errors.crop?.remove_factor}
                                    </FormHelperText>
                                  )
                                }
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4}>
                              <FormControl fullWidth variant="outlined">
                                <InputLabel
                                  size="small"
                                  htmlFor="crop.root_depth"
                                  error={touched.crop?.root_depth && Boolean(errors.crop?.root_depth)}
                                >
                                  Root Depth
                                </InputLabel>
                                <OutlinedInput
                                  size="small"
                                  name="crop.root_depth"
                                  id="crop.root_depth"
                                  label="Root Depth"
                                  value={values.crop.root_depth}
                                  onChange={handleChange}
                                  onKeyDown={numericOnly}
                                  inputProps={{ step: 0.01, min: 0 }}
                                  error={touched.crop?.root_depth && Boolean(errors.crop?.root_depth)}
                                  type="number"
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <Box mr={1}>[m]</Box>
                                      <PopoverCustom info={"Maximum root depth. See Nemecek et al. 2019, section 3.9.7.2"}/>
                                    </InputAdornment>
                                  }
                                />
                                {
                                  touched.crop?.root_depth &&
                                  errors.crop?.root_depth && (
                                    <FormHelperText error>
                                      {errors.crop?.root_depth}
                                    </FormHelperText>
                                  )
                                }
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4}>
                              <FormControl fullWidth variant="outlined">
                                <InputLabel
                                  size="small"
                                  htmlFor="crop.dry_mass_carbon"
                                >
                                  C in Dry Mass
                                </InputLabel>
                                <OutlinedInput
                                  size="small"
                                  name="crop.dry_mass_carbon"
                                  id="crop.dry_mass_carbon"
                                  label="C in Dry Mass"
                                  value={ values.crop.dry_mass_carbon?.toString() || "" }
                                  onChange={handleChange}
                                  onKeyDown={numericOnly}
                                  inputProps={{ step: 0.01, min: 0, max: 1 }}
                                  error={ touched.crop?.dry_mass_carbon && Boolean(errors.crop?.dry_mass_carbon)}
                                  type="number"
                                  endAdornment={
                                    <InputAdornment
                                      position="end"
                                      sx={{ width: "70%" }}
                                    >
                                      <Box
                                        sx={{
                                          whiteSpace: "normal",
                                          lineHeight: "1.2",
                                        }}
                                      >
                                        [kg C / kg DM]
                                      </Box>
                                      <PopoverCustom
                                        info={"Carbon content in crop dry mass"}
                                      />
                                    </InputAdornment>
                                  }
                                />
                                {touched.crop?.dry_mass_carbon &&
                                  errors.crop?.dry_mass_carbon && (
                                    <FormHelperText error>
                                      {errors.crop?.dry_mass_carbon}
                                    </FormHelperText>
                                  )}
                              </FormControl>
                            </Grid>
                            <Grid container spacing={2} p={2} ml={2}>
                              <Grid
                                item
                                xs={12}
                                display={"flex"}
                                alignItems="center"
                                gap={2}
                                sx={{
                                  backgroundColor: "#F5F5F5",
                                  paddingBottom: 2,
                                  marginLeft: 2,
                                  marginTop: 1,
                                  borderRadius: 3,
                                }}
                              >
                                <Typography
                                  variant="subtitle"
                                  sx={{ fontWeight: "bold" }}
                                >
                                  Propagating Material
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={6} md={4} lg={4}>
                                <TextField
                                  required
                                  fullWidth
                                  size="small"
                                  name="crop.seed.type"
                                  select
                                  label="Type of Propagating Material"
                                  value={values.crop.seed.type}
                                  onChange={(event) => {
                                    handleChangeSeedType(event, setFieldValue, values);
                                    if (event.target.value !== "seedling") {
                                      setFieldValue("crop.seed.unit", "kg/ha");
                                    } else {
                                      setFieldValue("crop.seed.unit", "");
                                    }
                                  }}
                                  error={ touched.crop?.seed?.type && Boolean(errors.crop?.seed?.type)}
                                  helperText={ touched.crop?.seed?.type && errors.crop?.seed?.type }
                                >
                                  {seedType.registros.map((option) => (
                                    <MenuItem key={option} value={option}>
                                      {option}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Grid>
                              <Grid item xs={12} sm={6} md={4} lg={4}>
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel
                                    required
                                    size="small"
                                    htmlFor="crop.seed.amount"
                                    error={ touched.crop?.seed?.amount && Boolean(errors.crop?.seed?.amount) }
                                  >
                                    Amount
                                  </InputLabel>
                                  <OutlinedInput
                                    size="small"
                                    name="crop.seed.amount"
                                    id="crop.seed.amount"
                                    label="Amount"
                                    value={ values.crop.seed.amount?.toString() || "" }
                                    onChange={handleChange}
                                    onKeyDown={numericOnly}
                                    inputProps={{ step: 0.1, min: 0 }}
                                    error={ touched.crop?.seed?.amount && Boolean(errors.crop?.seed?.amount) }
                                    type="number"
                                  />
                                  {touched.crop?.seed?.amount &&
                                    errors.crop?.seed?.amount && (
                                      <FormHelperText error>
                                        {errors.crop?.seed?.amount}
                                      </FormHelperText>
                                    )}
                                </FormControl>
                              </Grid>


                              <Grid item xs={12} sm={6} md={4} lg={4}>
                                <TextField
                                  disabled={ !(values.crop.seed.type === "seedling") }
                                  required
                                  fullWidth
                                  size="small"
                                  name="crop.seed.unit"
                                  select
                                  label="Unit"
                                  value={ values.crop.seed.type === "seedling" ? values.crop.seed.unit : "kg/ha" }
                                  defaultValue={"kg/ha"}
                                  onChange={handleChange}
                                  error={ touched.crop?.seed?.unit && Boolean(errors.crop?.seed?.unit) }
                                  helperText={ touched.crop?.seed?.unit && errors.crop?.seed?.unit}
                                  InputProps={{
                                    endAdornment: (
                                      values.crop.seed.type === "seedling" ? (
                                        <InputAdornment position="end" sx={{ mr:3 }}>
                                          <PopoverCustom info={<span><strong>p</strong> refers to the number of seedlings</span>} />
                                        </InputAdornment>
                                      ) : null
                                    )
                                  }}
                                >
                                  {
                                    seedUnitSeedling.registros.map((option) => (
                                      <MenuItem key={option} value={option}>
                                        {option}
                                      </MenuItem>
                                    ))
                                  }
                                </TextField>
                              </Grid>


                              <Grid
                                item
                                xs={12}
                                display={"flex"}
                                alignItems="center"
                                gap={2}
                                sx={{
                                  backgroundColor: "#F5F5F5",
                                  paddingBottom: 2,
                                  marginLeft: 2,
                                  marginTop: 1,
                                  borderRadius: 3,
                                }}
                              >
                                <Typography
                                  variant="subtitle"
                                  sx={{ fontWeight: "bold" }}
                                >
                                  Heavy Metal Content
                                </Typography>
                              </Grid>

                              <Grid item xs={12} sm={6} md={4} lg={4}>
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel
                                    size="small"
                                    htmlFor="crop.dry_mass_heavy_metal_content.Cd"
                                  >
                                    Cd content
                                  </InputLabel>
                                  <OutlinedInput
                                    size="small"
                                    name="crop.dry_mass_heavy_metal_content.Cd"
                                    id="crop.dry_mass_heavy_metal_content.Cd"
                                    label="Cd content"
                                    value={
                                      values.crop.dry_mass_heavy_metal_content?.Cd?.toString() ||
                                      ""
                                    }
                                    onChange={handleChange}
                                    onKeyDown={numericOnly}
                                    inputProps={{ step: 0.01, min: 0, max: 1 }}
                                    error={
                                      touched.crop?.dry_mass_heavy_metal_content
                                        ?.Cd &&
                                      Boolean(
                                        errors.crop?.dry_mass_heavy_metal_content
                                          ?.Cd
                                      )
                                    }
                                    type="number"
                                    endAdornment={
                                      <InputAdornment
                                        position="end"
                                        sx={{ width: "80%" }}
                                      >
                                        <Box
                                          sx={{
                                            whiteSpace: "normal",
                                            lineHeight: "1.2",
                                          }}
                                        >
                                          [kg Cd / kg DM]
                                        </Box>
                                        <PopoverCustom
                                          info={
                                            "Cadmium content in crop dry mass"
                                          }
                                        />
                                      </InputAdornment>
                                    }
                                  />
                                  {touched.crop?.dry_mass_heavy_metal_content
                                    ?.Cd &&
                                    errors.crop?.dry_mass_heavy_metal_content
                                      ?.Cd && (
                                      <FormHelperText error>
                                        {
                                          errors.crop
                                            ?.dry_mass_heavy_metal_content?.Cd
                                        }
                                      </FormHelperText>
                                    )}
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sm={6} md={4} lg={4}>
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel
                                    size="small"
                                    htmlFor="crop.dry_mass_heavy_metal_content.Cu"
                                  >
                                    Cu content
                                  </InputLabel>
                                  <OutlinedInput
                                    size="small"
                                    name="crop.dry_mass_heavy_metal_content.Cu"
                                    id="crop.dry_mass_heavy_metal_content.Cu"
                                    label="Cu content"
                                    value={
                                      values.crop.dry_mass_heavy_metal_content?.Cu?.toString() ||
                                      ""
                                    }
                                    onChange={handleChange}
                                    onKeyDown={numericOnly}
                                    inputProps={{ step: 0.01, min: 0, max: 1 }}
                                    error={
                                      touched.crop?.dry_mass_heavy_metal_content
                                        ?.Cu &&
                                      Boolean(
                                        errors.crop?.dry_mass_heavy_metal_content
                                          ?.Cu
                                      )
                                    }
                                    type="number"
                                    endAdornment={
                                      <InputAdornment
                                        position="end"
                                        sx={{ width: "80%" }}
                                      >
                                        <Box
                                          sx={{
                                            whiteSpace: "normal",
                                            lineHeight: "1.2",
                                          }}
                                        >
                                          [kg Cu / kg DM]
                                        </Box>
                                        <PopoverCustom
                                          info={"Copper content in crop dry mass"}
                                        />
                                      </InputAdornment>
                                    }
                                  />
                                  {touched.crop?.dry_mass_heavy_metal_content
                                    ?.Cu &&
                                    errors.crop?.dry_mass_heavy_metal_content
                                      ?.Cu && (
                                      <FormHelperText error>
                                        {
                                          errors.crop
                                            ?.dry_mass_heavy_metal_content?.Cu
                                        }
                                      </FormHelperText>
                                    )}
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sm={6} md={4} lg={4}>
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel
                                    size="small"
                                    htmlFor="crop.dry_mass_heavy_metal_content.Zn"
                                  >
                                    Zn content
                                  </InputLabel>
                                  <OutlinedInput
                                    size="small"
                                    name="crop.dry_mass_heavy_metal_content.Zn"
                                    id="crop.dry_mass_heavy_metal_content.Zn"
                                    label="Zn content"
                                    value={
                                      values.crop.dry_mass_heavy_metal_content?.Zn?.toString() ||
                                      ""
                                    }
                                    onChange={handleChange}
                                    onKeyDown={numericOnly}
                                    inputProps={{ step: 0.01, min: 0, max: 1 }}
                                    error={
                                      touched.crop?.dry_mass_heavy_metal_content
                                        ?.Zn &&
                                      Boolean(
                                        errors.crop?.dry_mass_heavy_metal_content
                                          ?.Zn
                                      )
                                    }
                                    type="number"
                                    endAdornment={
                                      <InputAdornment
                                        position="end"
                                        sx={{ width: "80%" }}
                                      >
                                        <Box
                                          sx={{
                                            whiteSpace: "normal",
                                            lineHeight: "1.2",
                                          }}
                                        >
                                          [kg Zn / kg DM]
                                        </Box>
                                        <PopoverCustom
                                          info={"Zinc content in crop dry mass"}
                                        />
                                      </InputAdornment>
                                    }
                                  />
                                  {touched.crop?.dry_mass_heavy_metal_content
                                    ?.Zn &&
                                    errors.crop?.dry_mass_heavy_metal_content
                                      ?.Zn && (
                                      <FormHelperText error>
                                        {
                                          errors.crop
                                            ?.dry_mass_heavy_metal_content.Zn
                                        }
                                      </FormHelperText>
                                    )}
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sm={6} md={4} lg={4}>
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel
                                    size="small"
                                    htmlFor="crop.dry_mass_heavy_metal_content.Pb"
                                  >
                                    Pb content
                                  </InputLabel>
                                  <OutlinedInput
                                    size="small"
                                    name="crop.dry_mass_heavy_metal_content.Pb"
                                    id="crop.dry_mass_heavy_metal_content.Pb"
                                    label="Pb content"
                                    value={
                                      values.crop.dry_mass_heavy_metal_content?.Pb?.toString() ||
                                      ""
                                    }
                                    onChange={handleChange}
                                    onKeyDown={numericOnly}
                                    inputProps={{ step: 0.01, min: 0, max: 1 }}
                                    error={
                                      touched.crop?.dry_mass_heavy_metal_content
                                        ?.Pb &&
                                      Boolean(
                                        errors.crop?.dry_mass_heavy_metal_content
                                          ?.Pb
                                      )
                                    }
                                    type="number"
                                    endAdornment={
                                      <InputAdornment
                                        position="end"
                                        sx={{ width: "80%" }}
                                      >
                                        <Box
                                          sx={{
                                            whiteSpace: "normal",
                                            lineHeight: "1.2",
                                          }}
                                        >
                                          [kg Pb / kg DM]
                                        </Box>
                                        <PopoverCustom
                                          info={"Lead content in crop dry mass"}
                                        />
                                      </InputAdornment>
                                    }
                                  />
                                  {touched.crop?.dry_mass_heavy_metal_content
                                    ?.Pb &&
                                    errors.crop?.dry_mass_heavy_metal_content
                                      ?.Pb && (
                                      <FormHelperText error>
                                        {
                                          errors.crop
                                            ?.dry_mass_heavy_metal_content?.Pb
                                        }
                                      </FormHelperText>
                                    )}
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sm={6} md={4} lg={4}>
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel
                                    size="small"
                                    htmlFor="crop.dry_mass_heavy_metal_content.Ni"
                                  >
                                    Ni content
                                  </InputLabel>
                                  <OutlinedInput
                                    size="small"
                                    name="crop.dry_mass_heavy_metal_content.Ni"
                                    id="crop.dry_mass_heavy_metal_content.Ni"
                                    label="Ni content"
                                    value={
                                      values.crop.dry_mass_heavy_metal_content?.Ni?.toString() ||
                                      ""
                                    }
                                    onChange={handleChange}
                                    onKeyDown={numericOnly}
                                    inputProps={{ step: 0.01, min: 0, max: 1 }}
                                    error={
                                      touched.crop?.dry_mass_heavy_metal_content
                                        ?.Ni &&
                                      Boolean(
                                        errors.crop?.dry_mass_heavy_metal_content
                                          ?.Ni
                                      )
                                    }
                                    type="number"
                                    endAdornment={
                                      <InputAdornment
                                        position="end"
                                        sx={{ width: "80%" }}
                                      >
                                        <Box
                                          sx={{
                                            whiteSpace: "normal",
                                            lineHeight: "1.2",
                                          }}
                                        >
                                          [kg Ni / kg DM]
                                        </Box>
                                        <PopoverCustom
                                          info={"Nickel content in crop dry mass"}
                                        />
                                      </InputAdornment>
                                    }
                                  />
                                  {touched.crop?.dry_mass_heavy_metal_content
                                    ?.Ni &&
                                    errors.crop?.dry_mass_heavy_metal_content
                                      ?.Ni && (
                                      <FormHelperText error>
                                        {
                                          errors.crop
                                            ?.dry_mass_heavy_metal_content?.Ni
                                        }
                                      </FormHelperText>
                                    )}
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sm={6} md={4} lg={4}>
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel
                                    size="small"
                                    htmlFor="crop.dry_mass_heavy_metal_content.Cr"
                                  >
                                    Cr content
                                  </InputLabel>
                                  <OutlinedInput
                                    size="small"
                                    name="crop.dry_mass_heavy_metal_content.Cr"
                                    id="crop.dry_mass_heavy_metal_content.Cr"
                                    label="Cr content"
                                    value={
                                      values.crop.dry_mass_heavy_metal_content?.Cr?.toString() ||
                                      ""
                                    }
                                    onChange={handleChange}
                                    onKeyDown={numericOnly}
                                    inputProps={{ step: 0.01, min: 0, max: 1 }}
                                    error={
                                      touched.crop?.dry_mass_heavy_metal_content
                                        ?.Cr &&
                                      Boolean(
                                        errors.crop?.dry_mass_heavy_metal_content
                                          ?.Cr
                                      )
                                    }
                                    type="number"
                                    endAdornment={
                                      <InputAdornment
                                        position="end"
                                        sx={{ width: "80%" }}
                                      >
                                        <Box
                                          sx={{
                                            whiteSpace: "normal",
                                            lineHeight: "1.2",
                                          }}
                                        >
                                          [kg Cr / kg DM]
                                        </Box>
                                        <PopoverCustom
                                          info={
                                            "Chromium content in crop dry mass"
                                          }
                                        />
                                      </InputAdornment>
                                    }
                                  />
                                  {touched.crop?.dry_mass_heavy_metal_content
                                    ?.Cr &&
                                    errors.crop?.dry_mass_heavy_metal_content
                                      ?.Cr && (
                                      <FormHelperText error>
                                        {
                                          errors.crop
                                            ?.dry_mass_heavy_metal_content?.Cr
                                        }
                                      </FormHelperText>
                                    )}
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sm={6} md={4} lg={4}>
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel
                                    size="small"
                                    htmlFor="crop.dry_mass_heavy_metal_content.Hg"
                                  >
                                    Hg content
                                  </InputLabel>
                                  <OutlinedInput
                                    size="small"
                                    name="crop.dry_mass_heavy_metal_content.Hg"
                                    id="crop.dry_mass_heavy_metal_content.Hg"
                                    label="Hg content"
                                    value={
                                      values.crop.dry_mass_heavy_metal_content?.Hg?.toString() ||
                                      ""
                                    }
                                    onChange={handleChange}
                                    onKeyDown={numericOnly}
                                    inputProps={{ step: 0.01, min: 0, max: 1 }}
                                    error={
                                      touched.crop?.dry_mass_heavy_metal_content
                                        ?.Hg &&
                                      Boolean(
                                        errors.crop?.dry_mass_heavy_metal_content
                                          ?.Hg
                                      )
                                    }
                                    type="number"
                                    endAdornment={
                                      <InputAdornment
                                        position="end"
                                        sx={{ width: "80%" }}
                                      >
                                        <Box
                                          sx={{
                                            whiteSpace: "normal",
                                            lineHeight: "1.2",
                                          }}
                                        >
                                          [kg Hg / kg DM]
                                        </Box>
                                        <PopoverCustom
                                          info={
                                            "Mercury content in crop dry mass"
                                          }
                                        />
                                      </InputAdornment>
                                    }
                                  />
                                  {touched.crop?.dry_mass_heavy_metal_content
                                    ?.Hg &&
                                    errors.crop?.dry_mass_heavy_metal_content
                                      ?.Hg && (
                                      <FormHelperText error>
                                        {
                                          errors.crop
                                            ?.dry_mass_heavy_metal_content?.Hg
                                        }
                                      </FormHelperText>
                                    )}
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sm={6} md={4} lg={4}>
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel
                                    size="small"
                                    htmlFor="crop.dry_mass_heavy_metal_content.As"
                                  >
                                    As content
                                  </InputLabel>
                                  <OutlinedInput
                                    size="small"
                                    name="crop.dry_mass_heavy_metal_content.As"
                                    id="crop.dry_mass_heavy_metal_content.As"
                                    label="As content"
                                    value={
                                      values.crop.dry_mass_heavy_metal_content?.As?.toString() ||
                                      ""
                                    }
                                    onChange={handleChange}
                                    onKeyDown={numericOnly}
                                    inputProps={{ step: 0.01, min: 0, max: 1 }}
                                    error={
                                      touched.crop?.dry_mass_heavy_metal_content
                                        ?.As &&
                                      Boolean(
                                        errors.crop?.dry_mass_heavy_metal_content
                                          ?.As
                                      )
                                    }
                                    type="number"
                                    endAdornment={
                                      <InputAdornment
                                        position="end"
                                        sx={{ width: "80%" }}
                                      >
                                        <Box
                                          sx={{
                                            whiteSpace: "normal",
                                            lineHeight: "1.2",
                                          }}
                                        >
                                          [kg As / kg DM]
                                        </Box>
                                        <PopoverCustom
                                          info={
                                            "Arsenic content in crop dry mass"
                                          }
                                        />
                                      </InputAdornment>
                                    }
                                  />
                                  {touched.crop?.dry_mass_heavy_metal_content
                                    ?.As &&
                                    errors.crop?.dry_mass_heavy_metal_content
                                      ?.As && (
                                      <FormHelperText error>
                                        {
                                          errors.crop
                                            ?.dry_mass_heavy_metal_content?.As
                                        }
                                      </FormHelperText>
                                    )}
                                </FormControl>
                              </Grid>
                            </Grid>
                          </Grid>
                        </>
                      ) : null
                    }
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <Button
                      size="small"
                      onClick={handleClose}
                      variant="outlined"
                      startIcon={<CancelRoundedIcon />}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="small"
                      variant="contained"
                      startIcon={<SaveRoundedIcon />}
                      autoFocus
                      type="submit"
                    >
                      save
                    </Button>
                  </Box>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userAll: {
      loading: state.userAll.loading,
      registro: state.userAll.registro,
      error: state.userAll.error,
    },
    plotType: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationPlotType,
      error: state.enumerated.error,
    },
    seedType: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationSeedType,
      error: state.enumerated.error,
    },
    seedUnitSeedling: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationSeedUnitSeedling,
      error: state.enumerated.error,
    },
    transformFrom: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationPlotTransformFrom,
      error: state.enumerated.error,
    },
    loadTreatment: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationPlotLoadTreatment,
      error: state.enumerated.error,
    },
    cropName: {
      loading: state.enumerated.loading,
      registros: state.enumerated.enumerationPlotCropName,
      error: state.enumerated.error,
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserAll: (user_id) => dispatch(fetchUserAll(user_id)),
    fetchEnumerationPlotType: () => dispatch(fetchEnumerationPlotType()),
    fetchEnumerationSeedType: (crop) => dispatch(fetchEnumerationSeedType(crop)),
    fetchEnumerationSeedUnitSeedling: () => dispatch(fetchEnumerationSeedUnitSeedling()),
    fetchEnumerationPlotTransformFrom: () => dispatch(fetchEnumerationPlotTransformFrom()),
    fetchEnumerationPlotLoadTreatment: () => dispatch(fetchEnumerationPlotLoadTreatment()),
    fetchEnumerationPlotCropName: () => dispatch(fetchEnumerationPlotCropName()),
    addPlot: (params) => dispatch(addPlot(params)),
    editPlotActivity: (params) => dispatch(editPlotActivity(params)),
    checkActivityStartDateIsNotConflicted: (params) => dispatch(checkActivityStartDateIsNotConflicted(params)),
    checkActivityPeriodIsNotConflicted: (params) => dispatch(checkActivityPeriodIsNotConflicted(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlotForm);
